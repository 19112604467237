let initialState = {
  raiseRequest:[],
  raiseRequestMsg:'',
  hrReqArr:[],
  updateStatusMsg:'',
  btnFlag: false
}

export function addKey(data){

    data.forEach((item) =>{
      if (item.reqStatus === 'Rejected' || item.reqStatus === 'Completed') {
        item['disableFlag'] = false;
      }
      else {
        item['disableFlag'] = true;
        item['enableBtn'] = true;
      }
    });

    return data;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'POST_USER_REQUESTED':
        return {
          ...state,
          raiseRequestMsg:'Wait...'
        }
    case 'POST_USER_SUCCESS':
        return {
          ...state,
          raiseRequest:action.response.data.data,
          raiseRequestMsg:action.response.data.msg
        }
    case 'POST_USER_FAILURE':
        return {
          ...state,
          raiseRequestMsg:action.msg
        }
    case 'GET_USER_REQUEST_REQUESTED':
        return {
          ...state,
          btnFlag: true
        }
    case 'GET_USER_REQUEST_SUCCESS':
        return {
          ...state,
          hrReqArr: addKey(action.response.data.data),
          btnFlag: false
        }
    case 'GET_USER_REQUEST_FAILURE':
        return {
          ...state,
          btnFlag: false
        }
    case 'REQUEST_UPDATE_REQUESTED':
        return {
          ...state,
          updateStatusMsg:'',
          btnFlag:true
        }
    case 'REQUEST_UPDATE_SUCCESS':
        return {
          ...state,
          updateStatusMsg:action.response.data.msg,
          btnFlag:false
        }
    case 'REQUEST_UPDATE_FAILURE':
        return {
          ...state,
          updateStatusMsg:action.msg,
          btnFlag:false
        }
    case 'RAISE_QUERY_REQUESTED':
        return{
          ...state,
          btnFlag:true,
          updateStatusMsg:''
        }
    case 'RAISE_QUERY_SUCCESS':
        return{
          ...state,
          btnFlag:false,
          updateStatusMsg:action.response.data.msg
        }
    case 'RAISE_QUERY_FAILURE':
        return{
          ...state,
          btnFlag:false,
          updateStatusMsg:action.msg
        }
    default:
      return state;
  }
}
