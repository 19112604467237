import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './Route/routes';

class App extends React.Component {
  render() {
    return (
        <Routes />
    );
  }

}

export default App;
