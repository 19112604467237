import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './userAdd.css';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {handleApiCall} from './../../../Redux/actions/index';
import {ADD_USER_API, MANAGER_API, EDIT_PROFILE_API, LOGGEDIN_EMP_API,
        DELETE_EMPLOYEE_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';
import moment from 'moment';

class UserAdd extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fname:'',
      lname:'',
      userEmail: '',
      userPwd:'',
      organisation:localStorage.getItem('role') == 'Admin' ? 'MeConIt' : localStorage.getItem('organisation'),
      joiningDate: '',
      gender:'Male',
      mgID: 0,
      designation:'',
      userRole:'Employee',
      managerName:'',
      msgHide:true,
      fieldMsg:"",
      employee:'',
      employeeId:'',
      formOption:'Add',
      CurrentRole:'',
      emp_Id:'',
      allEmp:[],
      ErrMsg:''
    }
  }
  componentDidMount(){
    this.getManagerList();
  }
  static getDerivedStateFromProps(props, state) {
    if (state.allEmp !== props.allEmp) {
      if (state.employeeId !== '') {
        let userObj = {};
        let arr = [...props.allEmp];
        arr.forEach((item) => {
          if (item.emp_Id === state.employeeId) {
            userObj = {...item}
          }
        });
        return {
          employee: userObj.first_name === null ? "": userObj.first_name,
          allEmp: props.allEmp,
          userEmail: userObj.email === null ? "": userObj.email,
          userPwd: userObj.password === null ? "": userObj.password,
          designation: userObj.designation === null ? "": userObj.designation,
          joiningDate: userObj.joining_date === null ? "": moment(userObj.joining_date, 'DD-MM-YYYY').toDate(),
          gender: userObj.gender === null ? "": userObj.gender,
          organisation: userObj.organisation,
          lname: userObj.last_name === null ? "": userObj.last_name,
          fname: userObj.first_name === null ? "": userObj.first_name,
          mgID: userObj.managerId,
          CurrentRole: userObj.role,
          emp_Id: userObj.emp_Id,
          userRole: userObj.role
        };
      }
      else {
        return {
          allEmp: props.allEmp,
      }
    }
  }

    // Return null to indicate no change to state.
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.props.addEmpMsg !== prevProps.addEmpMsg){
      if(this.props.addEmpMsg === 'Employee Details updated Successfully' ||
      this.props.addEmpMsg === 'Employee Removed Successfully'){
        this.getManagerList();
        let user = localStorage.getItem('userID');
        store.dispatch(handleApiCall(LOGGEDIN_EMP_API, 'GET', 'GET_EMP', {}, user,MOCK_FLAG));
      }
      else if (this.props.addEmpMsg === 'Employee Added Successfully') {
        this.getManagerList();
      }
    }
  }

  getManagerList = () =>{
    let obj = {
      "organisation":localStorage.getItem('organisation')
    }
    let user = localStorage.getItem('userID');
    store.dispatch(handleApiCall(MANAGER_API, 'GET', 'GET_MANAGER', obj, user,MOCK_FLAG));
  }
  handleEmail(e) {
    this.setState({userEmail: e.target.value});
  }
  handlePwd(e) {
    this.setState({userPwd: e.target.value});
  }
  handleDesignation(e) {
    this.setState({designation: e.target.value});
  }

  handleJoiningDate = date => {
    this.setState({joiningDate: date});
  }

  genderChangeEvent = (value, id) => {
    this.setState({gender:value});
  }
  orgChangeEvent = (value) => {
    this.setState({organisation:value});
  }
  formOptionChangeEvent = (value) => {
    this.setState({formOption: value});
	this.setState({ErrMsg: ''});
    this.resetData();
    //this.getManagerList();
    if (value !== 'Add') {
      let user = localStorage.getItem('userID');
      store.dispatch(handleApiCall(LOGGEDIN_EMP_API, 'GET', 'GET_EMP', {}, user,MOCK_FLAG));
    }
    else {
      this.setState({employee: ''});
      this.setState({employeeId: ''});
    }
  }
  userChangeEvent = (userID, name, manager) => {
    this.setState({
      employee: name,
      employeeId: userID,
      ErrMsg:''});
    let userObj = {};
    let arr = [...this.props.allEmp];
    let managerName = '';
    arr.forEach((item) => {
      if (item.emp_Id === userID) {
        userObj = {...item}
      }
      if (manager === item.emp_Id) {
        managerName = item.name;
      }
    });
    this.setState({
      userEmail: userObj.email == null ? "": userObj.email,
      userPwd: userObj.password == null ? "": userObj.password,
      designation: userObj.designation == null ? "": userObj.designation,
      joiningDate: userObj.joining_date == null ? "": moment(userObj.joining_date, 'DD-MM-YYYY').toDate(),
      gender: userObj.gender == null ? "": userObj.gender,
      organisation: userObj.organisation,
      lname: userObj.last_name == null ? "": userObj.last_name,
      fname: userObj.first_name == null ? "": userObj.first_name,
      mgID: userObj.managerId,
      CurrentRole: userObj.role,
      emp_Id: userObj.emp_Id,
      userRole: userObj.role,
      managerName: managerName
    });
  }
  LastName(e){
    this.setState({lname: e.target.value})
  }
  firstName(e){
    this.setState({fname: e.target.value})
  }
  editEmployee = (event) => {
    event.preventDefault();
    let submitFlag = true;
    var form = document.getElementById('personalInfo');
    let allEmp = this.state.allEmp;
    let mgID = this.state.mgID;
    if (form.checkValidity()) {
       if (this.state.employee !== '') {
          if (this.state.userRole !== this.state.CurrentRole) {
              if (this.state.userRole !== 'MD') {
                if (this.state.userRole === 'Employee') {
                  if (this.props.managerList.length < 1) {
                    submitFlag = false;
                    this.setState({fieldMsg:"No Managers Present. Add Manager First."});
                  }
                  else if (this.state.mgID === 0) {
                    submitFlag = false;
                    this.setState({fieldMsg:"*Select Manager Id."});
                  }
                }
              }
              else {
                mgID = 0;
              }
          }
          if (submitFlag) {
            this.setState({msgHide: false});
            this.setState({fieldMsg:""});
			      this.setState({ErrMsg:''});
            form.classList.remove('was-validated');
            let obj ={
              email:this.state.userEmail ,
              designation:this.state.designation ,
              joining_date: this.state.joiningDate !== '' ? moment(this.state.joiningDate).format("DD-MM-YYYY") : "" ,
              gender:this.state.gender ,
              organisation:this.state.organisation ,
              last_name:this.state.lname ,
              first_name:this.state.fname ,
              managerId:mgID ,
              role:this.state.userRole ,
            }
            let user = this.state.emp_Id;
            store.dispatch(handleApiCall(EDIT_PROFILE_API, 'POST', 'UPDATE_PROFILE', obj, user,MOCK_FLAG));
          }
      }
    }
    else {
      form.classList.add('was-validated');
     this.setState({ErrMsg:"please select employee"});
    }

  }


  deleteEmployee =(event) =>{
   event.preventDefault();

   if(this.state.emp_Id !== '')
   {
     this.setState({msgHide:false, ErrMsg: ''});
    let user = this.state.emp_Id;
    store.dispatch(handleApiCall(DELETE_EMPLOYEE_API, 'GET', 'DELETE_EMPLOYEE', {}, user,MOCK_FLAG));
   }
   else{
    this.setState({ErrMsg:"please select employee"});
   }

  }


  addEmployee = (event) => {
    event.preventDefault();
    var form = document.getElementById('personalInfo');
    let submitFlag = true;
    if (form.checkValidity()) {
      if ((localStorage.getItem('role') === 'Admin') &&
         (this.state.userRole === 'Employee')) {
          if (this.props.managerList.length < 1) {
            submitFlag = false;
            this.setState({fieldMsg:"No Managers Present. Add Manager First."});
          }
          else if (this.state.mgID === 0) {
            submitFlag = false;
            this.setState({fieldMsg:"*Select Manager Id."});
          }
      }
      if (submitFlag) {
        this.setState({msgHide: false});
        this.setState({fieldMsg:""});
        let mgId = 0;
        if (localStorage.getItem('role') === 'Manager') {
          mgId = localStorage.getItem('userID')
        }
        else if(localStorage.getItem('role') === 'Admin'){
          if (this.state.userRole !== 'MD') {
            mgId =  this.state.mgID;
          }
        }
        form.classList.remove('was-validated');
        let joindate = moment(this.state.joiningDate, 'YYYY/MM/DD').format("DD-MM-YYYY");
        let obj = {
          "first_name": this.state.fname,
          "last_name": this.state.lname,
          "gender": this.state.gender,
          "joining_date": joindate,
          "organisation": this.state.organisation,
          "email": this.state.userEmail,
          "password": this.state.userPwd,
          "designation": this.state.designation,
          "managerId": mgId,
          "role": this.state.userRole
        }
        store.dispatch(handleApiCall(ADD_USER_API, 'POST', 'ADD_USER', obj, '',MOCK_FLAG));
      }
    }
    else {
      form.classList.add('was-validated');
    }
  }
  handleradioChange = (value) => {
    // if (value === 'Employee') {
    //   this.getManagerList();
    // }
      this.setState({userRole: value});
  }
  managerChangeEvent = (key, value) =>{
    this.setState({
      mgID: key,
      managerName: value
    })
  }
  resetData = () => {
    //this.getManagerList();
    var form = document.getElementById('personalInfo');
    form.classList.remove('was-validated');
    this.setState({
      fname:'',
      lname:'',
      userEmail: '',
      userPwd:'',
      joiningDate: '',
      gender:'Male',
      mgID: 0,
      designation:'',
	  organisation:localStorage.getItem('role') == 'Admin' ? 'MeConIt' : localStorage.getItem('organisation'),
      userRole:'Employee',
      managerName:'',
      fieldMsg:"",
      employee:'',
      employeeId:'',
      CurrentRole:'',
      emp_Id:'',
      msgHide:true
    });
  }
  render() {
    return (
      <div>
              <div id="profile">
                  <h3>Manage Employee</h3>
                  <hr />
                  <div className={`profileForm ${localStorage.getItem('role')+'User'}`}>
                  <form id="personalInfo">
                  {localStorage.getItem('role') === 'Admin' &&
                   <div className="row">
                   <div className="col-sm-2">
                   <b><span>Select Option:</span></b>
                   <DropdownButton id="dropdown-basic-button" title={this.state.formOption}>
                     <Dropdown.Item
                       onClick={() => this.formOptionChangeEvent('Add')}
                       className=""
                       id="Add">Add</Dropdown.Item>
                     <Dropdown.Item
                       onClick={() => this.formOptionChangeEvent('Edit')}
                       className=""
                       id="Edit">Edit</Dropdown.Item>
                       <Dropdown.Item
                       onClick={() => this.formOptionChangeEvent('Remove')}
                       className=""
                       id="Remove">Remove</Dropdown.Item>
                   </DropdownButton>
                   </div>
                   {((this.state.formOption === 'Edit') || (this.state.formOption === 'Remove') )&&
                    <div className="col-sm-2">
                    <b><span>Select Employee:</span></b>
                    <DropdownButton id="dropdown-basic-button" title={this.state.employee}>
                      {this.props.allEmp.length > 0 && this.props.allEmp.map((item) => {
                        if (item.role !== 'Admin' && item.role !== 'HR') {
                          return (
                            <Dropdown.Item
                              onClick={() => this.userChangeEvent(item.emp_Id, item.name, item.managerId)}
                              className=""
                              id="user">{item.name}</Dropdown.Item>
                          )
                        }
                      })
                      }

                    </DropdownButton>
                    </div>}

                    <div className="errormsg">{this.state.ErrMsg}</div>
                    </div>}

                    {this.state.formOption !== 'Remove' &&

                  <div className="row">
                    <div className="col-sm-3">
                    <b><span>Select Organisation:</span></b>
                    <DropdownButton id="dropdown-basic-button" title={this.state.organisation}>
                      <Dropdown.Item
                        onClick={() => this.orgChangeEvent('MeConIt')}
                        className=""
                        id="MeConIt">MeConIt</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.orgChangeEvent('Hoch-Rein')}
                        className=""
                        id="Hoch-Rein">Hoch-Rein</Dropdown.Item>
                    </DropdownButton>
                    </div>
                    <div className="col-sm-3">
                    <label for="fname">First Name:</label>
                    <input type="text"
                    className="form-control"
                    id="fname"
                    value={this.state.fname}
                    onChange={this.firstName.bind(this)}
                    placeholder="Enter First Name"
                    name="fname" required/>
                    </div>
                    <div className="col-sm-3">
                    <label for="last_name">Last Name:</label>
                    <input type="text"
                    className="form-control"
                    id="lname"
                    value={this.state.lname}
                    onChange={this.LastName.bind(this)}
                    placeholder="Enter Last Name"
                    name="lname" required/>
                    </div>
                  </div>}

                  {this.state.formOption !== 'Remove' &&
                  <div className="row">
                    <div className="col-sm-3">
                    <label for="email">Email:</label>
                      <input type="email"
                      title="Valid domain are @meconit or @hoch-rein"
                      pattern="^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(meconit.com|meconit.in|hoch-rein.com|hoch-rein.in)$"
                      value={this.state.userEmail}
                      onChange={this.handleEmail.bind(this)}
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      name="email" required/>
                    </div>

                    {this.state.formOption === 'Add' &&
                    <div className="col-sm-3">
                    <label for="pswd">Password:</label>
                      <input type="password"
                      value={this.state.userPwd}
                      onChange={this.handlePwd.bind(this)}
                      className="form-control"
                      id="pswd"
                      placeholder="Enter password"
                      name="pswd" required/>
                    </div>}

                    <div className="col-sm-3">
                    <label for="designation">Designation:</label>
                      <input type="text"
                      className="form-control"
                      id="designation"
                      value={this.state.designation}
                      onChange={this.handleDesignation.bind(this)}
                      placeholder="Enter Designation"
                      name="designation" required/>
                    </div>
                  </div>}
          {this.state.formOption !== 'Remove' &&
                  <div className="row">
                    <div className="col-sm-2">
                    <b><span>Select Joining Date:</span></b>
                    <DatePicker
                        required={true}
                        placeholderText="select date"
                        dateFormat="dd MMM yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={this.state.joiningDate}
                        onChange={this.handleJoiningDate}
                        className="form-control" />
                    </div>
                    <div className="col-sm-2">
                    <b><span>Gender:</span></b>
                    <DropdownButton id="dropdown-basic-button" title={this.state.gender}>
                      <Dropdown.Item
                        onClick={() => this.genderChangeEvent('Male', 'M')}
                        className=""
                        id="male">Male</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.genderChangeEvent('Female', 'F')}
                        className=""
                        id="female">Female</Dropdown.Item>
                    </DropdownButton>
                    </div>
                    {this.state.formOption === 'Edit' &&
                     this.state.employee !== '' &&
                      <div className="col-sm-2">
                        <label for="CurrentRole">Current Role:</label>
                          <input type="text"
                          className="form-control"
                          id="CurrentRole"
                          value={this.state.CurrentRole}
                          placeholder=""
                          name="CurrentRole" disabled/>
                      </div>}
                    {localStorage.getItem('role') === 'Admin' &&
                    <div className="col-sm-2">
                    <b><span>Select Role:</span></b>
                    <DropdownButton id="dropdown-basic-button" title={this.state.userRole}>
                      <Dropdown.Item
                        onClick={() => this.handleradioChange('Employee')}
                        className=""
                        id="Employee">Employee</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleradioChange('Manager')}
                        className=""
                        id="Manager">Manager</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.handleradioChange('MD')}
                        className=""
                        id="MD">MD</Dropdown.Item>
                    </DropdownButton>
                    </div>}
                        {
                        localStorage.getItem('role') === 'Admin' &&
                        this.state.userRole !== 'MD' &&
                          <div className="col-sm-2" id="managerid">
                          <b><span>Select Manager ID:</span></b>
                        <DropdownButton id="dropdown-basic-button" title={this.state.managerName}>
                        {
                          this.props.managerList.length > 0 && this.props.managerList.map((item) =>{
                            if (this.state.emp_Id !== item.emp_Id) {
                              return (
                                <Dropdown.Item
                                  onClick={() => this.managerChangeEvent(item.emp_Id, item.name)}
                                  className=""
                                  id={item.name}>{item.name}</Dropdown.Item>
                              )
                            }
                          })
                        }
                        </DropdownButton></div>}
						<div className="errormsg">{this.state.fieldMsg}</div>
                    </div>

                      }
                       {this.state.formOption !== 'Remove' &&
                  <div className="row userForm">
                    <div className="offset-5 col-sm-2">

                        {this.state.formOption === 'Add' ?
                        <React.Fragment>
                        <Button type="submit" variant="success" onClick={this.addEmployee} class="btn btn-primary">Add</Button>
                        <Button variant="primary" onClick={this.resetData} class="btn btn-primary">Reset</Button>
                        </React.Fragment>
                        :

                        <Button type="submit" variant="success" onClick={this.editEmployee} class="btn btn-primary">Save</Button>}

                        </div>
                  </div>}
                  {this.state.formOption =='Remove' &&
                    <div className="Removebtn">
                   <Button type="submit" variant="success" onClick={this.deleteEmployee} class="btn btn-primary">Remove</Button>
                    </div>
                  }


                  {!this.state.msgHide &&
                  <div>
                  <div className="offset-5 col-sm-4">
                      <p><b>{this.props.addEmpMsg}</b></p>
                  </div>

                  </div>}

</form>


                  </div>
              </div>

          </div>
    );

  }

}



const mapStateToProps = (state) => {
    return {
      allEmp:state.Leave.allEmp,
      userInfo:state.Leave.userInfo,
      managerList: state.Leave.managerList,
      addEmpMsg:state.Leave.addEmpMsg
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAdd);
