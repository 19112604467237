import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './news.css';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {handleApiCall} from './../../../Redux/actions/index';
import {ADD_NEWS_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';

class NewsFeed extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      news:'',
      addNewsMsg:'',
      msgHide: true,
      organisation:'MeConIt'
    }
  }
  componentDidMount(){
    this.setState({news:''});
  }
  static getDerivedStateFromProps(props, state) {
    if (state.addNewsMsg !== props.addNewsMsg) {
      return {
        addNewsMsg: props.addNewsMsg
      }
    }
  return null;
  }
  newsInputHandler(e) {
    this.setState({news: e.target.value});
  }
  sendFeed = (event) => {
    event.preventDefault();
    var form = document.getElementById('newsFormInfo');
    if (this.state.news.trim() !== '') {
      form.classList.remove('was-validated');
      this.setState({msgHide: false});
      let obj = {
        organisation: this.state.organisation,
        notificationInfo: this.state.news
      }
      let user = localStorage.getItem('userID');
      store.dispatch(handleApiCall(ADD_NEWS_API, 'POST', 'ADD_NEWSFEED', obj, user,MOCK_FLAG));
    }
    else {
      this.setState({news: ''});
      form.classList.add('was-validated');
    }
  }
  resetData = () => {
    this.setState({
      news: '',
      addNewsMsg:'',
      msgHide: true,
      organisation:'MeConIt'});
  }

  formOptionChangeEvent = (value) => {
    this.setState({organisation: value});
  }
  render() {
    return (
      <div>
        <div id="newsFeed">
          <h3>Add News</h3>
          <hr />
          <div className="newsForm">
            <form id="newsFormInfo">
            <div className="row">
            <div className="col-sm-2">
            <b><span>Select organisation:</span></b>
            <DropdownButton id="dropdown-basic-button" title={this.state.organisation}>
            <Dropdown.Item
              onClick={() => this.formOptionChangeEvent('MeConIt')}
              className=""
              id="MeConIt">MeConIt</Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.formOptionChangeEvent('Hoch-Rein')}
                className=""
                id="Hoch-Rein">Hoch-Rein</Dropdown.Item>
              </DropdownButton>
            </div>
            </div>
              <div className="row">
                <div className="col-sm-4">
                <label for="message">Message:</label>
                <textarea
                  className="form-control"
                  rows="6"
                  id="message"
                  value={this.state.news}
                  onChange={this.newsInputHandler.bind(this)}
                  name="message" required>
                </textarea>
                </div>
              </div>
              <div className="row btnCss">
                <div className="offset-5 col-sm-2">
                    <Button class="btn btn-primary" type="submit" onClick={this.sendFeed}>Send</Button>
                    <Button variant="primary" id="resetBtn" onClick={this.resetData}>Reset</Button>
                </div>
              </div>
              {!this.state.msgHide &&
                <div className="row">
                  <div className="offset-5 col-sm-4">
                    <p><b>{this.props.addNewsMsg}</b></p>
                  </div>
                </div>}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      addNewsMsg: state.Leave.addNewsMsg
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsFeed);
