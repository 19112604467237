import React, {Component} from 'react';
import './Apply.css';
import {connect} from'react-redux';
import { bindActionCreators } from 'redux';
import {handleApiCall} from './../../../Redux/actions/index';
import {HR_REQ_APPLY_API, LOGGEDIN_EMP_API, GET_NOTIFICATION_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';
import { Button } from 'react-bootstrap';


class Apply extends Component{
	constructor(props){
		super(props)
		this.state={
			fields:{},
			errors:{},
			value: 'stationary',
			txtvalue: '',
      msgHide: true
		}
	}
  componentDidMount(){
		let user = localStorage.getItem('userID');
    store.dispatch(handleApiCall(LOGGEDIN_EMP_API, 'GET', 'GET_EMP', {}, user,MOCK_FLAG));
		store.dispatch(handleApiCall(GET_NOTIFICATION_API, 'GET', 'GET_ALERTS', {}, user,MOCK_FLAG));
    this.setState({msgHide: true});
  }
	handleChange(field, e){
		let fields= this.state.fields;
		fields[field]= e.target.value;
		this.setState({fields});
	};

    handleText= (event) => {

    	this.setState({txtvalue: event.target.value})
    };
    handleSelect = (event) => {
        this.setState({ value: event.target.value })
    };

	handleValidation =(event) =>{
		event.preventDefault();
		let errors={};
		let IsValid=false;
	    if (this.state.value === '') {
		   IsValid = true;
		   errors.categories="Please Select Categories";
		}
    if (this.state.txtvalue === '') {
      IsValid = true;
      errors.categories="Description cannot be empty";
    }

      this.setState({errors:errors})
	   if (!IsValid) {
       this.setState({msgHide: false});
			let user = localStorage.getItem('userID');
      let obj={
        "requirement": this.state.value,
        "description": this.state.txtvalue
        }
      store.dispatch(handleApiCall(HR_REQ_APPLY_API, 'POST', 'POST_USER', obj, user,MOCK_FLAG));
		};
}


	render(){
		return(
      <div>
              <div id="profile">
                  <h3>Raise Request</h3>
                  <hr />
		     <form className="req-form" onSubmit={this.handleValidation}>
			  <div>
			    <label>Employee Name:</label>
			    <input type="text"
			           name="ename"
			           value={localStorage.getItem('name')}
			           onChange={this.handleChange.bind(this,"ename")}
			    disabled={true} />
			  <div className="errmsg">
			    {this.state.errors.ename}
			  </div>
			  </div>


  			  <div>
  			     <label>Email Id:</label>
  			     <input type="email"
  			            name="email"
  			            value={this.props.userInfo.email}
  			            onChange={this.handleChange.bind(this,"email")}
  			     disabled={true} />
  			  <div className="errmsg">
			    {this.state.errors.email}
			  </div>
  			  </div>
  			  <div>
  			     <label>Select categories:</label>
  			     <select name="categories"
  			             value={this.state.value}
  			             onChange={this.handleSelect.bind(this)}>
  			             <option value='stationary'>Stationary</option>
  			             <option value='enquiry'>Enquiry</option>
  			     </select>
  			     <div className="errmsg">
			    {this.state.errors.categories}
			  </div>
  			  </div>
  			  <div>
  			     <label>Description:</label>
  			     <textarea name="desc"
  			               placeholder="Description"
  			               value={this.state.txtvalue}
  			               onChange={this.handleText.bind(this)} required/>


  			  </div>
			  <div>
			     <Button type="submit">Apply</Button>
			  </div>
        {!this.state.msgHide &&
          <div className="row">
            <div className="offset-2 col-sm-4">
              <p><b>{this.props.raiseRequestMsg}</b></p>
            </div>
          </div>}
            </form>
            </div>
		 </div>
     )
	}
}

const mapStateToProps = state =>{
  return{
   userInfo:state.Leave.userInfo,
   raiseRequest:state.Hr.raiseRequest,
   raiseRequestMsg:state.Hr.raiseRequestMsg
  }
}

const mapDispatchToProps = (dispatch) => {
 return bindActionCreators({
 }, dispatch);
}

export default
connect(
mapStateToProps,
mapDispatchToProps
)(Apply);
