import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handleApiCall } from "./../../../Redux/actions/index";
import { CommonActions } from "./../../../Redux/actions/CommonActions";
import {
  LOGIN_API,
  GENERATE_OTP_API,
  RESET_PWD_API,
  MOCK_FLAG,
} from "./../../../utils";
import loginImg from "../../../images/loginImg.png";
import CompLogo from "./../../../images/CompLogo.jpg";
import store from "./../../../Redux/store";
import "./styles.css";
import { Redirect } from "react-router-dom";
// import { Login } from './components/login/index';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tester: true,
      email: "",
      pwd: "",
      formText: "Login",
      resetEmail: "",
      otp: null,
      otpMsg: "",
      otpFlag: false,
      resetPwd: "",
      resetFlag: false,
      resetMsg: "",
      btnDisable: false,
      loginErrFlag: true,
      loginMsg: "",
    };
  }
  componentDidMount() {
    this.setState({ formText: "Login" });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.resetMsg !== state.resetMsg) {
      return {
        resetMsg: props.resetMsg,
      };
    }

    if (props.loginMsg !== state.loginMsg) {
      return {
        loginMsg: props.loginMsg,
      };
    }

    if (props.otpMsg !== state.otpMsg) {
      if (props.otpMsg === "User Does Not exist") {
        return {
          otpMsg: props.otpMsg,
          btnDisable: false,
        };
      } else {
        return {
          otpMsg: props.otpMsg,
          formText: "Otp",
        };
      }
    }
    if (props.otpFlag !== state.otpFlag) {
      return {
        otpFlag: props.otpFlag,
      };
    }
    // Return null to indicate no change to state.
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.resetFlag) {
      if (prevState.resetMsg !== this.state.resetMsg) {
        this.setState({ resetFlag: false });
      }
    }
    if (prevState.otpFlag !== this.state.otpFlag) {
      if (this.state.otpFlag === true) {
        this.setState({ formText: "Otp" });
      }
    }
  }
  handleEmail(e) {
    this.setState({ email: e.target.value });
  }
  handlePwd(e) {
    this.setState({ pwd: e.target.value });
  }
  processDataAsycn = async (email, pwd) => {};
  handleClick = (event) => {
    event.preventDefault();

    var re = /\S+@\S+\.\S+/;

    if (re.test(this.state.email) && this.state.pwd !== "") {
      //this.props.login(email);
      let obj = {
        email: this.state.email,
        password: this.state.pwd,
      };
      //await this.props.handleApiCall('/login', 'POST', 'LOGIN', obj, '');
      this.setState({ loginErrFlag: false });
      store.dispatch(
        handleApiCall(LOGIN_API, "POST", "LOGIN", obj, "", MOCK_FLAG)
      );
    }
  };
  resetHandler = (event) => {
    event.preventDefault();
    this.setState({
      formText: "Reset",
      loginMsg: "",
      loginErrFlag: true,
      email: "",
      pwd: "",
    });
  };
  resetPassword = (event) => {
    event.preventDefault();
    this.setState({
      btnDisable: true,
      otpMsg: "",
    });
    let obj = {
      email: this.state.resetEmail,
    };

    store.dispatch(
      handleApiCall(GENERATE_OTP_API, "POST", "GET_OTP", obj, "", MOCK_FLAG)
    );
  };
  handleOtpEmail(e) {
    this.setState({ resetEmail: e.target.value });
  }
  handleOtp(e) {
    this.setState({ otp: e.target.value });
  }
  verifyOtp = (event) => {
    event.preventDefault();
    let obj = {
      email: this.state.resetEmail,
      otp: this.state.otp,
      reset_password: this.state.resetPwd,
    };
    this.setState({
      resetFlag: true,
      resetMsg: "",
    });
    store.dispatch(
      handleApiCall(RESET_PWD_API, "POST", "RESET_PWD", obj, "", MOCK_FLAG)
    );
  };
  cancelEmail = (event) => {
    event.preventDefault();
    this.setState({
      formText: "Login",
      resetEmail: "",
      btnDisable: false,
    });
  };
  cancelOtp = (event) => {
    this.setState({ formText: "Login" });
  };
  handleResetPwd(e) {
    this.setState({ resetPwd: e.target.value });
  }
  resetField = (event) => {
    this.setState({
      formText: "Login",
      resetEmail: "",
      btnDisable: false,
    });
  };

  tourHandler() {
    let userTour = "TourID_" + localStorage.getItem("userID");
    if (localStorage.getItem(userTour) === null) {
      this.props.setTourFlag(true);
    } else {
      this.props.setTourFlag(false);
    }
  }
  render() {
    if (localStorage.getItem("userID") !== null) {
      return <Redirect to="/landing-screen" />;
    }
    return (
      <div className="outerContainer1 d-flex align-items-center justify-content-center">
        {/* <img className="bodyImg" src={loginImg} alt="Background"></img> */}
        {/* <div className="col-12 col-sm-10"> */}
        <div className="innerContainer col-10 col-sm-8 col-md-7 col-lg-4">
          <header>
            <div style={{ overflow: "auto" }}>
              <img className="logoCss" src={CompLogo} alt="Company Logo"></img>
            </div>
          </header>
          <p className="loginPgNoteCss">
            Only for Hoch.Rein-MeConIt Employees.
          </p>
          <section>
            <div className="form">
              {this.state.formText === "Login" && (
                <form
                  className="form-horizontal"
                  id="loginForm"
                  onSubmit={this.handleClick}
                >
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <input
                        type="email"
                        title="Valid domain are @meconit or @hoch-rein"
                        pattern="^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(meconit.com|meconit.in|hoch-rein.com|hoch-rein.in)$"
                        value={this.state.email}
                        onChange={this.handleEmail.bind(this)}
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-12">
                      <input
                        type="password"
                        value={this.state.pwd}
                        onChange={this.handlePwd.bind(this)}
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        name="password"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group mt-4 d-flex justify-content-center align-items-center">
                    {/* <div className=""> */}
                      {/* <Button variant="success" type="submit" id="loginBtn">
                        Login
                      </Button> */}
                      <button
                        id="loginBtn"
                        onClick={this.handleClick}
                        className="formButtons mr-4"
                      >
                        Login
                      </button>
                    {/* </div> */}
                    {/* <div> */}
                      {/* <Button
                        variant="success"
                        id="forgotBtn"
                        onClick={this.resetHandler}
                        className='forgotPassBtn'>
                          Forgot password
                      </Button> */}
                      <button
                        id="forgotBtn"
                        onClick={this.resetHandler}
                        className="formButtons"
                      >
                        Forgot password
                      </button>
                    {/* </div> */}
                  </div>
                  <div className="col-12">
                    {!this.state.loginErrFlag && (
                      <p style={{ "text-align": "center", color: "#ce0808" }}>
                        {this.props.loginMsg}
                      </p>
                    )}
                  </div>
                </form>
              )}
              {this.state.formText === "Otp" && (
                <form
                  className="form-horizontal"
                  id="otpForm"
                  onSubmit={this.verifyOtp}
                >
                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="email"
                        title="Valid domain are @meconit or @hoch-rein"
                        pattern="^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(meconit.com|meconit.in|hoch-rein.com|hoch-rein.in)$"
                        value={this.state.resetEmail}
                        onChange={this.handleOtpEmail.bind(this)}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        value={this.state.otp}
                        onChange={this.handleOtp.bind(this)}
                        className="form-control"
                        placeholder="Enter Otp"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="password"
                        value={this.state.resetPwd}
                        onChange={this.handleResetPwd.bind(this)}
                        className="form-control"
                        id="password"
                        placeholder="New Password"
                        name="password"
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-12">
                      <Button variant="success" type="submit" id="otpBtn">
                        Reset
                      </Button>
                      {this.props.resetMsg ===
                        "Password Reset successfully." && (
                        <Button
                          variant="success"
                          id="otpBackBtn"
                          onClick={this.resetField}
                        >
                          Go to Login
                        </Button>
                      )}
                    </div>
                  </div>
                  {this.props.resetMsg !== "" && (
                    <div className="form-group">
                      <div className="col-12">
                        <p>
                          <b>{this.props.resetMsg}</b>
                        </p>
                      </div>
                    </div>
                  )}
                </form>
              )}
              {this.state.formText === "Reset" && (
                <form
                  className="form-horizontal"
                  id="resetPassword"
                  onSubmit={this.resetPassword}
                >
                  <div className="form-group">
                    <div className="col-sm-12">
                      <input
                        type="email"
                        title="Valid domain are @meconit or @hoch-rein"
                        pattern="^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(meconit.com|meconit.in|hoch-rein.com|hoch-rein.in)$"
                        value={this.state.resetEmail}
                        onChange={this.handleOtpEmail.bind(this)}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-12">
                      <p>
                        <b>
                          You will receive an otp on the above email for
                          resetting your password.
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="offset-3 col-9">
                      <Button
                        variant="success"
                        type="submit"
                        id="otpBtn"
                        disabled={this.state.btnDisable}
                      >
                        Send
                      </Button>
                      <Button
                        variant="success"
                        id="otpBackBtn"
                        onClick={this.cancelEmail}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  {this.state.btnDisable && (
                    <div className="form-group">
                      <div className="offset-3 col-9">
                        <p>
                          <b>Wait for few seconds...</b>
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.otpMsg && (
                    <div className="form-group">
                      <div className="offset-3 col-9">
                        <p>
                          <b>{this.state.otpMsg}</b>
                        </p>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </div>
          </section>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginMsg: state.Leave.loginMsg,
    otpMsg: state.Leave.otpMsg,
    resetMsg: state.Leave.resetMsg,
    userInfo: state.Leave.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setTourFlag: CommonActions.setTourFlag,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
