import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import {
  Redirect,
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import store from './../Redux/store';
import Header from './../components/Common/Navbar/navbar';
import {API_URL, PUSH_NOTIFICATION_API, VERIFY_TOKEN_API, LOGOUT_API, MOCK_FLAG} from './../utils';
import {handleApiCall} from './../Redux/actions/index';
import Login from './../components/Common/login/login';
import LandingComponent from './../components/Common/Landing-Screen/LandingScreen';

let source = null;

class Routes extends React.Component {
  constructor(props){
    super(props);
    this.state ={
    }
  }
  componentDidMount(){
    store.dispatch(handleApiCall(VERIFY_TOKEN_API, 'GET', 'VERIFY_TOKEN', {}, '',MOCK_FLAG));

    if (localStorage.getItem('userID') !== null) {
      if (source === null) {
        source = new EventSource(API_URL+PUSH_NOTIFICATION_API);
        source.onmessage = (event) =>{
                let response = [];
                response.push(JSON.parse(event.data));
                let Arr = JSON.parse(event.data);
                if (Arr.news === true) {
                  if (localStorage.getItem('organisation') === Arr.organisation) {
                    store.dispatch({ type: 'GET_PUSH_NOTIFICATION_SUCCESS', response})
                  }
                }
                else {
                  if (Arr.empId == localStorage.getItem('userID')) {
                    store.dispatch({ type: 'GET_PUSH_NOTIFICATION_SUCCESS', response})
                  }
                }
              };
            source.onerror = (err) =>{
              console.log(JSON.stringify(err));
            }
      }
    }
  }
  componentDidUpdate(prevProps, prevState){
    if (this.props.tokenMsg !== prevProps.tokenMsg) {
      if (this.props.tokenMsg === 'Invalid token') {
        store.dispatch(handleApiCall(LOGOUT_API, 'GET', 'LOGOUT', {}, '',MOCK_FLAG));
      }
    }
  }
   render() {
 return (
   <div>
   <BrowserRouter>


   {(localStorage.getItem('userID') === null || localStorage.getItem('userID') === 'null') &&
     <Redirect from="/" to="/login" />}
   <Route path='/login' component={Login} />
      <Switch>
         <PrivateRoute  path="*">
         <Header />
         </PrivateRoute>
      </Switch>
    </BrowserRouter>
    </div>
);
}
}



function PrivateRoute({ children, ...rest }) {
  if ((localStorage.getItem('userID') !== null) && rest.location.pathname === '/landing-screen') {
    return (
      <Route
        {...rest}
        render={({ location }) =>
           (
            <LandingComponent />
          )
        }
      />
    );
  }
  else {
    if (localStorage.getItem('userID') === null) {
      if (source !== null) {
        source.close();
      }
    }
    return (
      <Route
        {...rest}
        render={({ location }) =>
           localStorage.getItem('userID') !== null ? (
            children
          ) : (
            <Redirect from="/" to="/login" />
          )
        }
      />
    );
  }
}
const mapStateToProps = (state) => {
    return {
      loginRandonNo: state.Leave.loginRandonNo,
      tokenMsg: state.Leave.tokenMsg
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);
// export default withRouter();
