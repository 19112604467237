import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import {connect} from'react-redux';
import './RequestHistory.css';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {handleApiCall} from './../../../Redux/actions/index';
import {HR_REQUEST_LIST_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';
import Loader from 'react-loader-spinner';

let reqSortFlag = true;
let respSortFlag = true;

const mapStateToProps = state =>{
  return{
    hrReqArr:state.Hr.hrReqArr,
    randomNo: state.Leave.randomNo,
    btnFlag:state.Hr.btnFlag,
  }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    })
}

class RequestHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      randomNo: 0,
      hrReqArr:[]
    }
  }
  componentDidMount(){
    let user = localStorage.getItem('userID');
    store.dispatch(handleApiCall(HR_REQUEST_LIST_API, 'GET', 'GET_USER_REQUEST', {}, user,MOCK_FLAG));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.randomNo !== this.state.randomNo) {
      let user = localStorage.getItem('userID');
      store.dispatch(handleApiCall(HR_REQUEST_LIST_API, 'GET', 'GET_USER_REQUEST', {}, user,MOCK_FLAG));
    }
  }
  static getDerivedStateFromProps(props, state) {
      if (props.randomNo !== state.randomNo) {
        return {
          randomNo: props.randomNo
        }
      }
      if (props.hrReqArr !== state.hrReqArr) {
        return {
          hrReqArr: props.hrReqArr
        }
      }
  }

  reqDateSort = () =>{
    reqSortFlag = !reqSortFlag;
    let pointsToBeInserted = this.state.hrReqArr.sort((a,b) => {

        let x = a.createdOn, y = b.createdOn;
        if (x === y) {
          return 0;
        }
        else if (x === null) {
          return 1;
        }
        else if (y === null) {
         return -1;
       }
       else {
         if (reqSortFlag) {
           return x < y ? 1 : -1;
         }
         else {
           return x < y ? -1 : 1;
         }
       }

      });
    this.setState({hrReqArr: pointsToBeInserted});
  }

  respDateSort = () =>{
    respSortFlag = !respSortFlag;
    let pointsToBeInserted = this.state.hrReqArr.sort((a,b) => {

         let x = a.updatedOn, y = b.updatedOn;
         if (x === y) {
           return 0;
         }
         else if (x === null) {
           return 1;
         }
         else if (y === null) {
          return -1;
        }
        else {
          if (respSortFlag) {
            return x < y ? 1 : -1;
          }
          else {
            return x < y ? -1 : 1;
          }
        }


      });
    this.setState({hrReqArr: pointsToBeInserted});
  }

  render() {
    return (
    <div>
		{this.props.btnFlag &&
         <div className="row loaderclass loader load">

           <div className="loader-outer">
             <div className="loader-inner">
         <Loader
           type="Oval"
           color="white"
           height={50}
           width={50}

           //4 secs

         /> </div></div> </div>}
      <div  className="maintable">
       <Table striped bordered id="userTable">
            <thead>
            <tr>
            <th>#</th>
            <th>Requirement</th>
            <th>Description</th>
            <th onClick={this.reqDateSort}>Request Date<i class="fa fa-fw fa-sort"></i></th>
            <th onClick={this.respDateSort}>Response Date<i class="fa fa-fw fa-sort"></i></th>
            <th>Pending Response</th>
            <th>Status</th>
            <th>Reason for rejecting</th>
            </tr>
            </thead>
            <tbody>
            {this.state.hrReqArr.length > 0 && this.state.hrReqArr.map((item, index) => {
            return (

            <tr className={`${item.reqStatus === 'Query Raised' ? 'highlightRow' : ''}`}>

              <td>{index+1}</td>
              <td>{item.requirement}</td>
              <td>{item.description}</td>
              <td>{item.createdOn !== null && moment.utc(item.createdOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</td>
              <td>{item.updatedOn !== null && moment.utc(item.updatedOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</td>
              <td>{item.query}</td>
              <td>{item.reqStatus}</td>
              <td>{item.statusReason}</td>
            </tr>
      );
       })}

      </tbody>
      </Table>
      </div>
    </div>

    );
  }
}
export default
connect(
mapStateToProps,
mapDispatchToProps
)(RequestHistory);
