export default [
  {
    text:
      `
      <p>
      <b>Will take you to dashboard.</b>
      </p>
      `
    ,
    attachTo: { element: '.Link1', on: 'bottom' },
    classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        action() {
        return this.next();
      },
        text: 'Next'
      }
    ]
  },
  {
    text:
      `
      <p>
      <b>This tab will show your personal information. You can add/update your details.</b>
      </p>
      `
    ,
    attachTo: { element: '.Link2', on: 'bottom' },
    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
      <b>You can apply or track your leave by navigating to this tab.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') === 'Admin') {
        return false;
      }
      else {
        return true;
      }
    },
    attachTo: { element: '.Link3', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
      <b>New employee can be added here.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') === 'Employee') {
        return false;
      }
      else {
        return true;
      }
    },
    attachTo: { element: '.Link4', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
      <b>You can send news feed from here.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') === 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.Link5', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
      <b>You can add public holidays here.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') === 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.Link6', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
      <b>You can get attendance of employees month wise in pdf.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') === 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.Link9', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
        <b>From here you can:</b>
      <ol>
        <li><b>Switch between Leave app and Hr app.</b></li>
        <li><b>Logout</b></li>
      </ol>
      </p>
      `
    ,
    attachTo: { element: '.Link8', on: 'left' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
      <b>Displays your pending leaves.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') !== 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.square1', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
        <b>Displays no of leaves taken by you.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') !== 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.square2', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
        <b>Displays your Loss of Pay leaves.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') !== 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.square3', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
        <b>Displays your count of pending leave applications.</b>
      </p>
      `
    ,
    showOn() {
      if (localStorage.getItem('role') !== 'Admin') {
        return true;
      }
      else {
        return false;
      }
    },
    attachTo: { element: '.square4', on: 'bottom' },

    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {
        return this.next();
      },
      text: 'Next'
    }
    ]
  },
  {
    text:
      `
      <p>
        <b>Shows the recent activities in tabular format.</b>
      </p>
      `
    ,
    attachTo: { element: '.recentForm', on: 'top' },
    scrollTo: true,
    buttons: [
      {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Back'
    },
    {
      action() {

      this.next();
      },
      text: 'Next'
    }
  ]
},
{
  text:
    `
    <p>
      <b>Shows the recent activities in graphical format.</b>
    </p>
    `
  ,
  attachTo: { element: '.chartDiv', on: 'left' },
  scrollTo: true,
  buttons: [
    {
    action() {
      return this.back();
    },
    classes: 'shepherd-button-secondary',
    text: 'Back'
  },
  {
    action() {

    this.next();
    },
    text: 'Next'
  }
]
},
{
  text:
    `
    <p>
      <b>Displays upcoming events.</b>
    </p>
    `
  ,
  attachTo: { element: '.upcomingEvtIcon', on: 'right' },
  scrollTo: true,
  buttons: [
    {
    action() {
      return this.back();
    },
    classes: 'shepherd-button-secondary',
    text: 'Back'
  },
  {
    action() {

    this.next();
    },
    text: 'Next'
  }
]
},
{
  text:
    `
    <p>
      <b>Shows news feed.</b>
    </p>
    `
  ,
  attachTo: { element: '.newsIcon', on: 'left' },

  buttons: [
    {
    action() {
      return this.back();
    },
    classes: 'shepherd-button-secondary',
    text: 'Back'
  },
  {
    action() {
    let userTour = 'TourID_'+localStorage.getItem('userID');
    localStorage.setItem(userTour, false);
    this.complete();
    },
    text: 'Done'
  }
]
}
];
