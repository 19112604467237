import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import Table from 'react-bootstrap/Table';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

let reqSortFlag = true;
let respSortFlag = true;

class HistoryList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hrReqArr:[]
    }
  }
  componentDidMount(){
  }

  static getDerivedStateFromProps(props, state){
    if (state.hrReqArr !== props.hrReqArr) {
      return {
        hrReqArr: props.hrReqArr
      }
    }
    // Return null to indicate no change to state.
    return null;
  }

  reqDateSort = () =>{
    reqSortFlag = !reqSortFlag;
    let pointsToBeInserted = this.state.hrReqArr.sort((a,b) => {

        let x = a.createdOn, y = b.createdOn;
        if (x === y) {
          return 0;
        }
        else if (x === null) {
          return 1;
        }
        else if (y === null) {
         return -1;
       }
       else {
         if (reqSortFlag) {
           return x < y ? 1 : -1;
         }
         else {
           return x < y ? -1 : 1;
         }
       }

      });
    this.setState({hrReqArr: pointsToBeInserted});
  }

  respDateSort = () =>{
    respSortFlag = !respSortFlag;
    let pointsToBeInserted = this.state.hrReqArr.sort((a,b) => {

         let x = a.updatedOn, y = b.updatedOn;
         if (x === y) {
           return 0;
         }
         else if (x === null) {
           return 1;
         }
         else if (y === null) {
          return -1;
        }
        else {
          if (respSortFlag) {
            return x < y ? 1 : -1;
          }
          else {
            return x < y ? -1 : 1;
          }
        }


      });
    this.setState({hrReqArr: pointsToBeInserted});
  }

  render() {
    return (
      <Table striped bordered id="applicationListTable">
      <thead>
      <tr>
      <th>Name</th>
      <th>Requirement</th>
      <th>Description</th>
      <th onClick={this.reqDateSort}>Request Date<i class="fa fa-fw fa-sort"></i></th>
      <th onClick={this.respDateSort}>Response Date<i class="fa fa-fw fa-sort"></i></th>
      <th>Status</th>
      <th>Reason for rejecting</th>
      </tr>
      </thead>
      <tbody>
       {
         this.props.hrReqArr.map((item) =>{
           if (item.disableFlag === false) {
             return (
               <tr>
                 <td>
                   <a data-tip data-for={item.employee.email}>{item.employee.name}</a>
                   <ReactTooltip id={item.employee.email} type='info'>
                     <span>{item.employee.email}</span>
                   </ReactTooltip>
                 </td>
                 <td>{item.requirement}</td>
                 <td>{item.description}</td>
                 <td>{item.createdOn !== null && moment.utc(item.createdOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</td>
                 <td>{item.updatedOn !== null && moment.utc(item.updatedOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</td>
                 <td>{item.reqStatus}</td>
                 <td>
                   {item.statusReason}
                 </td>
               </tr>
             )
           }
         })
       }
      </tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      hrReqArr:state.Hr.hrReqArr
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryList);
