import React from "react";
import Profile from "./../../Leave/Profile/profile";
import Leaves from "./../../Leave/Leave-Comp/leaves";
import UserAdd from "./../../Leave/Add-User/userAdd";
import NewsFeed from "./../../Leave/Add-News/newsFeed";
import HolidayAdd from "./../../Leave/Holiday/addHoliday";
import AttendanceComp from "./../../Leave/Attendance/attendance";
import Home from "./../../Leave/Home/home";
import NotFound from "./../NotFound/notFound";
import Applications from "./../../Hr/Applications/Applications";
import Apply from "./../../Hr/Apply/Apply";
import RequestHistory from "./../../Hr/Request/RequestHistory";
import NotificationSystem from "react-notification-system";
import { CommonActions } from "./../../../Redux/actions/CommonActions";
import { Button, Modal } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store from "./../../../Redux/store";
import { handleApiCall } from "./../../../Redux/actions/index";
import { LOGOUT_API, MARK_READ_NOTIFY_API, MOCK_FLAG } from "./../../../utils";
import "./grid.css";
import "./navbar.css";
import gridIcon from "./../../../images/gridIcon.png";
import logout from "./../../../images/logout.png";
import switch_app from "./../../../images/switch_app.png";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import moment from "moment";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutModal: false,
      notificationArr: [],
      selectedApp: "",
      showOption: false,
    };
  }
  componentDidMount() {
    this.notificationSystem = React.createRef();
    this.setState({ selectedApp: this.props.selectedApp });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.notificationArr !== this.state.notificationArr) {
      let myArr = [];
      for (var i = 0; i < prevState.notificationArr.length; i++) {
        myArr.push(prevState.notificationArr[i].notificationId);
      }
      for (var i = 0; i < this.state.notificationArr.length; i++) {
        if (
          myArr.indexOf(this.state.notificationArr[i].notificationId) === -1
        ) {
          this.showNotification(i + 1, this.state.notificationArr[i]);
        }
      }
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.selectedApp !== state.selectedApp) {
      return {
        selectedApp: props.selectedApp,
      };
    }
    if (props.notificationArr !== state.notificationArr) {
      return {
        notificationArr: props.notificationArr,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  handleLogout = (event) => {
    let flag = this.state.showOption;
    this.setState({ showOption: !flag });
    this.setState({ logoutModal: true });
  };
  closeModal = (event) => {
    this.setState({ logoutModal: false });
  };
  logout = (event) => {
    this.setState({ logoutModal: false });
    store.dispatch({ type: "LOGOUT_SUCCESS" });
    //this.props.history.push('/login');
  };
  removeNotification = (notification) => {
    let notificationId = notification.children.props.notificationId;
    let news = notification.children.props.news;
    if (news === false) {
      store.dispatch(
        handleApiCall(
          MARK_READ_NOTIFY_API,
          "POST",
          "READ_NOTIFY",
          {},
          notificationId,
          MOCK_FLAG
        )
      );
    } else {
      let news = "newsCount_" + localStorage.getItem("userID");
      let Arr = JSON.parse(localStorage.getItem(news));
      Arr.push(notificationId);
      localStorage.setItem(news, JSON.stringify(Arr));
    }
  };
  showNotification = (index, object) => {
    this.notificationSystem.current.addNotification({
      message: <div></div>,
      autoDismiss: "0",
      level: "success",
      position: "tr",
      onRemove: this.removeNotification,
      children: (
        <div notificationId={object.notificationId} news={object.news}>
          <p>
            <b>Time: </b>
            {moment
              .utc(object.createdOn, "DD-MM-YYYY HH:mm:ss")
              .local()
              .format("DD-MM-YYYY HH:mm:ss")}
          </p>
          <p>
            <b>Message: </b>
            {object.notificationInfo}
          </p>
        </div>
      ),
    });
  };
  setApp = (value) => {
    this.tooltipHandler();
    this.setState({
      selectedApp: value,
    });
    this.props.setApp(value);
  };
  tooltipHandler = () => {
    let flag = this.state.showOption;
    this.setState({ showOption: !flag });
  };
  render() {
    let userName = localStorage.getItem("name");
    let userRole = localStorage.getItem("role") + ": ";
    let selectedApp = localStorage.getItem("selectedApp") + " App";
    return (
      <BrowserRouter>
        <div>
          <NotificationSystem ref={this.notificationSystem} />
          <Modal show={this.state.logoutModal} id="modalCss">
            <Modal.Header>
              <Modal.Title>Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>
                <b>Are you sure you want to logout ?</b>
              </h6>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.logout}>Logout</Button>
              <Button onClick={this.closeModal}>Cancel</Button>
            </Modal.Footer>
          </Modal>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            style={{ marginBottom: "20px" }}
          >
            <Navbar.Brand>MeConIT</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {localStorage.getItem("selectedApp") === "Leave" && (
                  <React.Fragment>
                    <Link to="/home">
                      <Button className="Link1">Home</Button>
                    </Link>

                    <Link to="/profile">
                      <Button className="Link2">Profile</Button>
                    </Link>

                    {localStorage.getItem("role") !== "Admin" && (
                      <Link to="/leaves">
                        <Button className="Link3">Leaves</Button>
                      </Link>
                    )}

                    {localStorage.getItem("role") !== "Employee" && (
                      <Link to="/manage-user">
                        <Button className="Link4">Manage Employee</Button>
                      </Link>
                    )}
                    {localStorage.getItem("role") === "Admin" && (
                      <React.Fragment>
                        <Link to="/news-feed">
                          <Button className="Link5">Manage Feed</Button>
                        </Link>
                        <Link to="/add-holidays">
                          <Button className="Link6">Holidays</Button>
                        </Link>
                        <Link to="/attendance">
                          <Button className="Link9">Reports</Button>
                        </Link>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {localStorage.getItem("selectedApp") === "Hr" &&
                  localStorage.getItem("role") !== "Admin" && (
                    <React.Fragment>
                      <Link to="/raise-request">
                        <Button className="Link3">Raise request</Button>
                      </Link>
                      <Link to="/request-history">
                        <Button className="Link3">Request History</Button>
                      </Link>
                    </React.Fragment>
                  )}
              </Nav>
              <Nav>
                <div className="form-inline usernameHeader">
                  {userRole} {userName} <span className="verticalLine"></span>{" "}
                  {selectedApp}
                </div>

                <div className="tooltip Link8">
                  <img
                    src={gridIcon}
                    alt="Grid Icon"
                    onClick={this.tooltipHandler}
                  />

                  {this.state.showOption &&
                    localStorage.getItem("role") !== "Admin" && (
                      <span className="tooltiptext">
                        <Link to="/home">
                          <Button
                            className=""
                            onClick={() => this.setApp("Leave")}
                          >
                            <img
                              className="iconCss"
                              alt="Switch Icon"
                              src={switch_app}
                            />
                            Leave App
                          </Button>
                        </Link>
                        <Link to="/raise-request">
                          <Button
                            className=""
                            onClick={() => this.setApp("Hr")}
                          >
                            <img
                              className="iconCss"
                              alt="Switch Icon"
                              src={switch_app}
                            />
                            Hr App
                          </Button>
                        </Link>
                        <Button
                          variant="success"
                          id="tileBtn"
                          onClick={this.handleLogout}
                        >
                          <img
                            className="iconCss"
                            alt="Logout Icon"
                            src={logout}
                          />
                          Logout
                        </Button>
                      </span>
                    )}
                  {this.state.showOption &&
                    localStorage.getItem("role") === "Admin" && (
                      <span className="tooltiptext">
                        <Link to="/home">
                          <Button
                            className=""
                            onClick={() => this.setApp("Leave")}
                          >
                            <img
                              className="iconCss"
                              alt="Switch Icon"
                              src={switch_app}
                            />
                            Leave App
                          </Button>
                        </Link>
                        <Link to="/applications">
                          <Button
                            className=""
                            onClick={() => this.setApp("Hr")}
                          >
                            <img
                              className="iconCss"
                              alt="Switch Icon"
                              src={switch_app}
                            />
                            Hr App
                          </Button>
                        </Link>
                        <Button
                          variant="success"
                          id="tileBtn"
                          onClick={this.handleLogout}
                        >
                          <img
                            className="iconCss"
                            alt="Logout Icon"
                            src={logout}
                          />
                          Logout
                        </Button>
                      </span>
                    )}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          {localStorage.getItem("role") === "Admin" && (
            <Switch>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/manage-user">
                <UserAdd />
              </Route>
              <Route path="/news-feed">
                <NewsFeed />
              </Route>
              <Route path="/add-holidays">
                <HolidayAdd />
              </Route>
              <Route path="/attendance">
                <AttendanceComp />
              </Route>
              <Route path="/applications">
                <Applications />
              </Route>
              <Route path="*" status={404}>
                <NotFound />
              </Route>
            </Switch>
          )}

          {(localStorage.getItem("role") === "Manager" ||
            localStorage.getItem("role") === "MD") && (
            <Switch>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/leaves">
                <Leaves />
              </Route>
              <Route path="/manage-user">
                <UserAdd />
              </Route>
              <Route path="/raise-request">
                <Apply />
              </Route>
              <Route path="/request-history">
                <RequestHistory />
              </Route>
              <Route path="*" status={404}>
                <NotFound />
              </Route>
            </Switch>
          )}

          {localStorage.getItem("role") === "Employee" && (
            <Switch>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/leaves">
                <Leaves />
              </Route>
              <Route path="/raise-request">
                <Apply />
              </Route>
              <Route path="/request-history">
                <RequestHistory />
              </Route>
              <Route path="*" status={404}>
                <NotFound />
              </Route>
            </Switch>
          )}

          {/*{<button type="button" className="newsIcon btn btn-info" onClick={this.addNotification}>Alerts</button>}*/}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Leave.userInfo,
    notificationArr: state.Leave.notificationArr,
    selectedApp: state.Leave.selectedApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setApp: CommonActions.setApp,
      logout: CommonActions.logout,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
