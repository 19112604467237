import React from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./leaves.css";
import subDays from "date-fns/subDays";
import moment from "moment";
import store from "./../../../Redux/store";
import { handleApiCall } from "./../../../Redux/actions/index";
import {
  HOLIDAY_LIST_API,
  LEAVE_UPDATE_API,
  LOGGEDIN_EMP_API,
  GET_LEAVES_API,
  MOCK_FLAG,
} from "./../../../utils";
import HolidayList from "./../Holiday/holidayList";
import MarkAbsent from "./../Mark-Absent/markAbsent";
import LeaveActions from "./../Leave-Action/leaveAction";
import ApplyLeave from "./../Apply-Leave/applyLeave";

var exactDiff = 0;
var publicHoliday = 0;

class Leaves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      minDate: "",
      pendingLeaves: 0,
      exactDiff: 0,
      publicHoliday: 0,
      id: "",
      status: "",
      modalFlag: false,
      selectDate: new Date(),
      checkFlag: false,
      holidayList: [],
      disableHolidayArr: [],
      rejectReason: null,
      selectedTab: "Apply",
    };
  }

  componentDidMount() {
    let user = localStorage.getItem("userID");
    let year = new Date().getFullYear();
    let obj = {
      fromDate: moment().startOf("year").format("DD-MM-YYYY"),
      toDate: moment().endOf("year").format("DD-MM-YYYY"),
    };
    store.dispatch(
      handleApiCall(LOGGEDIN_EMP_API, "GET", "GET_EMP", {}, user, MOCK_FLAG)
    );
    store.dispatch(
      handleApiCall(GET_LEAVES_API, "POST", "GET_LEAVES", obj, user, MOCK_FLAG)
    );
    //store.dispatch(handleApiCall(GET_NOTIFICATION_API, 'GET', 'GET_ALERTS', {}, user,MOCK_FLAG));
    store.dispatch(
      handleApiCall(
        HOLIDAY_LIST_API,
        "GET",
        "GET_HOLIDAYLIST",
        {},
        year,
        MOCK_FLAG
      )
    );
    var tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    tabcontent[0].style.display = "none";
    tablinks = document.getElementsByClassName("tablinks");
    tablinks[0].className = tablinks[0].className.replace(" active", "");

    document.getElementById("Apply").style.display = "block";
    document.getElementsByClassName("tablinks")[0].style.background = "#ccc";
    this.setState({ minDate: subDays(new Date(), 14) });
    //this.setState({pendingLeaves: parseInt(this.props.userInfo.leave_balance)});
  }

  static getDerivedStateFromProps(props, state) {
    if (props.holidayList !== state.holidayList) {
      let ArrList = [];
      if (props.holidayList.India) {
        let holidayArr = [...props.holidayList.India];
        holidayArr.forEach((item) => {
          ArrList.push(new Date(item.eventDate));
        });
      }
      return {
        holidayList: props.holidayList,
        disableHolidayArr: ArrList,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userInfo !== prevProps.userInfo) {
      this.setState({ pendingLeaves: this.props.userInfo.leave_balance });
    }
  }

  openTab = (evt, value) => {
    publicHoliday = 0;
    this.setState({
      startDate: "",
      endDate: "",
      exactDiff: 0,
      selectedTab: value,
      pendingLeaves: this.props.userInfo.leave_balance,
    });
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      document.getElementsByClassName("tablinks")[i].style.background = "none";
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(value).style.display = "block";
    evt.currentTarget.className += " active";
  };

  resetData = () => {
    this.setState({
      startDate: "",
      endDate: "",
      exactDiff: 0,
      pendingLeaves: this.props.userInfo.leave_balance,
    });
  };

  handleStartDate = (date) => {
    this.setState(
      {
        startDate: date,
        endDate: date,
        minDate: date,
      },
      () => this.calculateDiff()
    );
    if (
      (this.state.endDate !== "" && this.state.endDate < date) ||
      this.state.checkFlag === true
    ) {
      this.setState({ endDate: date }, () => this.calculateDiff());
    }
  };
  handleEndDate = (date) => {
    this.setState(
      {
        endDate: date,
      },
      () => this.calculateDiff()
    );
  };

  calculateDiff = () => {
    if (this.state.startDate !== "" && this.state.endDate !== "") {
      var date1 = new Date(this.state.startDate);
      var date2 = new Date(this.state.endDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var totalOff = 0;
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
      var isWeekend = (start, end) => {
        var d1 = new Date(start),
          d2 = new Date(end);
        let weekendDays = 0;
        let publicOff = 0;
        while (d1 < d2) {
          var filterday = d1.getDay();
          if (filterday === 6 || filterday === 0) {
            weekendDays++;
          } else {
            for (var x = 0; x < this.state.disableHolidayArr.length; x++) {
              if (
                new Date(d1).valueOf() ===
                new Date(this.state.disableHolidayArr[x]).valueOf()
              ) {
                publicOff++;
              }
            }
          }
          d1.setDate(d1.getDate() + 1);
        }
        totalOff = weekendDays + publicOff;
        publicHoliday = publicOff;
        return totalOff;
      };
      exactDiff =
        Difference_In_Days -
        isWeekend(this.state.startDate, this.state.endDate);
      if (this.state.checkFlag) {
        exactDiff -= 0.5;
      }
      this.setState({
        exactDiff: exactDiff,
        pendingLeaves: this.props.userInfo.leave_balance - exactDiff,
        publicHoliday: publicHoliday,
      });
    }
  };

  confirmModal = (status, uniqueId) => {
    this.setState({
      status: status,
      id: uniqueId,
      modalFlag: true,
      rejectReason: null,
    });
  };
  closeModal = () => {
    this.setState({ status: "", id: "", modalFlag: false, rejectReason: null });
  };
  leaveAction = (event) => {
    event.preventDefault();
    let obj = {
      leave_Id: this.state.id,
      leaveStatus: this.state.status,
      statusReason: this.state.rejectReason,
    };
    if (this.state.status === "Rejected") {
      var form = document.getElementById("rejectionForm");

      if (
        form.checkValidity() &&
        this.state.rejectReason !== null &&
        this.state.rejectReason.trim() !== ""
      ) {
        form.classList.remove("was-validated");
        this.setState({ modalFlag: false });
        store.dispatch(
          handleApiCall(
            LEAVE_UPDATE_API,
            "POST",
            "LEAVE_ACTION",
            obj,
            "",
            MOCK_FLAG
          )
        );
      } else {
        form.classList.add("was-validated");
        this.setState({ rejectReason: "" });
      }
    } else {
      this.setState({ modalFlag: false });
      store.dispatch(
        handleApiCall(
          LEAVE_UPDATE_API,
          "POST",
          "LEAVE_ACTION",
          obj,
          "",
          MOCK_FLAG
        )
      );
    }
  };

  handleCheckboxChange = (event) => {
    if (event.target.checked) {
      var now = this.state.startDate;
      this.setState({ endDate: now });
    }
    this.setState({ checkFlag: event.target.checked }, () =>
      this.calculateDiff()
    );
  };

  handleReasonChange(e) {
    this.setState({ rejectReason: e.target.value });
  }

  render() {
    return (
      <div>
        <div id="leave">
          <h4 classname='leaveTitle'>Leave Management</h4>
          <h6 className="leaveSubTitle">Apply or check status</h6>
          <Modal show={this.state.modalFlag} id="modalCss">
            <Modal.Header>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.status === "Approved" && (
                <b>Do you want to Approve leave application ?</b>
              )}
              {this.state.status === "Rejected" && (
                <div>
                  <b>Do you want to Reject leave application ?</b>
                  <form id="rejectionForm">
                    <input
                      type="text"
                      value={this.state.rejectReason}
                      className="form-control mb-2 mr-sm-2"
                      id="days"
                      placeholder="Enter reason for rejection"
                      onChange={this.handleReasonChange.bind(this)}
                      required
                    />
                  </form>
                </div>
              )}
              {this.state.status === "Cancelled" && (
                <b>Do you want to Cancel your leave application ?</b>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={this.leaveAction}>
                Yes
              </Button>
              <Button variant="danger" onClick={this.closeModal}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="leaveForm">
            <div className="tab">
              <button
                className="tablinks"
                onClick={(e) => this.openTab(e, "Apply")}
              >
                Apply
              </button>
              <button
                className="tablinks"
                onClick={(e) => this.openTab(e, "Holidays")}
              >
                Holidays
              </button>
              <button
                className="tablinks"
                onClick={(e) => this.openTab(e, "History")}
              >
                Leave Actions
              </button>
              {(this.props.userInfo.role === "Manager" ||
                this.props.userInfo.role === "MD") && (
                <button
                  className="tablinks"
                  onClick={(e) => this.openTab(e, "Pending")}
                >
                  Pending Actions
                </button>
              )}
              {(this.props.userInfo.role === "Manager" ||
                this.props.userInfo.role === "MD") && (
                <button
                  className="tablinks"
                  onClick={(e) => this.openTab(e, "Absent")}
                >
                  Mark Absent
                </button>
              )}
            </div>
            <div id="Apply" className="tabcontent">
              <ApplyLeave
                disableHolidayArr={this.state.disableHolidayArr}
                handleStartDate={this.handleStartDate}
                handleEndDate={this.handleEndDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.minDate}
                exactDiff={this.state.exactDiff}
                checkFlag={this.state.checkFlag}
                handleCheckboxChange={this.handleCheckboxChange}
                publicHoliday={publicHoliday}
                pendingLeaves={this.state.pendingLeaves}
                selectedTab={this.state.selectedTab}
                resetData={this.resetData}
              />
            </div>
            <div id="Holidays" className="tabcontent">
              <HolidayList holidayList={this.props.holidayList} />
            </div>

            <div id="History" className="tabcontent">
              <LeaveActions
                confirmModal={this.confirmModal}
                status={this.state.status}
                selectedTab={this.state.selectedTab}
              />
            </div>

            <div id="Pending" className="tabcontent">
              <LeaveActions
                confirmModal={this.confirmModal}
                status={this.state.status}
                selectedTab={this.state.selectedTab}
              />
            </div>

            <div id="Absent" className="tabcontent">
              <MarkAbsent
                disableHolidayArr={this.state.disableHolidayArr}
                handleStartDate={this.handleStartDate}
                handleEndDate={this.handleEndDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.minDate}
                exactDiff={this.state.exactDiff}
                selectedTab={this.state.selectedTab}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Leave.userInfo,
    holidayList: state.Leave.holidayList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaves);
