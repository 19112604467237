import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import subDays from "date-fns/subDays";
import { handleApiCall } from "./../../../Redux/actions/index";
import { LEAVE_APPLY_API, GET_LEAVES_API, MOCK_FLAG } from "./../../../utils";
import store from "./../../../Redux/store";

class ApplyLeave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      halfDayType: "First Half",
      desc: "",
      leaveType: "PL",
      msgHide: false,
      leaveApplyMessage: "",
    };
  }
  componentDidMount() {}
  static getDerivedStateFromProps(props, state) {
    if (state.leaveApplyMessage !== props.leaveApplyMessage) {
      return {
        leaveApplyMessage: props.leaveApplyMessage,
      };
    } 
    // Return null to indicate no change to state.
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedTab !== prevProps.selectedTab) {
      this.setState({
        halfDayType: "First Half",
        desc: "",
        leaveType: "PL",
        msgHide: true,
        leaveApplyMessage: "",
      });
    }
    if (this.state.leaveApplyMessage !== prevState.leaveApplyMessage) {
      if (this.state.leaveApplyMessage === "Leave Applied Successfully") {
        let obj = {
          fromDate: moment().startOf("year").format("DD-MM-YYYY"),
          toDate: moment().endOf("year").format("DD-MM-YYYY"),
        };
        let user = localStorage.getItem("userID");
        store.dispatch(
          handleApiCall(
            GET_LEAVES_API,
            "POST",
            "GET_LEAVES",
            obj,
            user,
            MOCK_FLAG
          )
        );
      }
    }
  }

  halfdayChangeEvent = (value) => {
    this.setState({ halfDayType: value });
  };

  dataTypeChangeEvent = (value) => {
    this.setState({ leaveType: value });
  };

  handleChange(e) {
    this.setState({ desc: e.target.value });
  }

  applyLeave = (event) => {
    event.preventDefault();
    //var form = document.getElementById('myForm');
    //if (form.checkValidity()) {
    //form.classList.remove('was-validated');
    var from = moment(this.props.startDate, "YYYY/MM/DD").format("DD-MM-YYYY");
    var to = moment(this.props.endDate, "YYYY/MM/DD").format("DD-MM-YYYY");
    var noOfDays = this.props.exactDiff;
    if (this.props.checkFlag) {
      noOfDays = 0.5;
    }
    this.setState({ msgHide: false });
    let obj = {
      fromDate: from,
      toDate: to,
      noOfDays: noOfDays,
      halfDay: this.props.checkFlag,
      halfDayType: this.props.checkFlag ? this.state.halfDayType : "",
      leaveType: this.state.leaveType,
      leavePurpose: this.state.desc,
    };
    let userid = localStorage.getItem("userID");
    store.dispatch(
      handleApiCall(
        LEAVE_APPLY_API,
        "POST",
        "LEAVE_APPLY",
        obj,
        userid,
        MOCK_FLAG
      )
    );
    //}
    // else {
    //   form.classList.add('was-validated');
    // }
  };
  resetHandler = () => {
    this.props.resetData();
    this.setState({ desc: "", msgHide: true });
  };
  render() {
    var pastDays = subDays(new Date(), 14);
    return (
      <form id="myForm" onSubmit={this.applyLeave}>
        <div className="form-inline row">
          <span className="col-12 col-sm-3">Leave Beginning From:</span>
          <DatePicker
            required={true}
            excludeDates={this.props.disableHolidayArr}
            placeholderText="select date"
            disabledKeyboardNavigation
            dateFormat="dd MMM yyyy"
            minDate={new Date(moment().startOf("month"))}
            selected={this.props.startDate}
            onChange={this.props.handleStartDate}
            className="form-control ml-3 ml-sm-0 col-12 col-sm-auto"
          />
          <b className="mr-auto  ml-3 ml-sm-4 mt-2 mr-sm-0 ">
            <input
              type="checkbox"
              checked={this.props.checkFlag}
              onChange={this.props.handleCheckboxChange}
            />{" "}
            Apply for half day ?
          </b>
        </div>

        {!this.props.checkFlag && (
          <div className="form-inline row">
            <span className="col-12 col-sm-3">Leave Ending On:</span>
            <DatePicker
              required={true}
              excludeDates={this.props.disableHolidayArr}
              placeholderText="select date"
              disabledKeyboardNavigation
              minDate={
                this.props.startDate || new Date(moment().startOf("month"))
              }
              dateFormat="dd MMM yyyy"
              selected={this.props.endDate}
              onChange={this.props.handleEndDate}
              className="form-control ml-3 ml-sm-0 col-12 col-sm-auto"
            />
          </div>
        )}
        {this.props.checkFlag && (
          <div className="form-inline row">
            <span className="col-12 col-sm-3">Select Half Day:</span>
            <DropdownButton
              id="dropdown-basic-button"
              title={this.state.halfDayType}
              className="ml-3 ml-sm-0"
            >
              <Dropdown.Item
                onClick={() => this.halfdayChangeEvent("First Half")}
                className=""
                id="First Half"
              >
                First Half
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.halfdayChangeEvent("Second Half")}
                className=""
                id="Second Half"
              >
                Second Half
              </Dropdown.Item>
            </DropdownButton>
          </div>
        )}
        <div className="form-inline row">
          <span className="col-12 col-sm-3">No of Days Applied:</span>
          <input
            type="text"
            value={this.props.exactDiff}
            className=" form-control mb-2 mr-3 mr-sm-0 ml-3 ml-sm-0  "
            // style={{width:'90%'}}
            id="days"
            disabled={true}
          />
          {this.props.publicHoliday > 0 && (
            <span className="holidayMsg">
              *Your Date Range contains {this.props.publicHoliday} public
              holidays.
            </span>
          )}
        </div>
        {localStorage.getItem("role") !== "MD" && (
          <div className="form-inline row">
            <span className="col-12 col-sm-3">Remaining Leaves:</span>
            <input
              type="text"
              value={this.props.pendingLeaves}
              className=" form-control mb-2 mr-3 mr-sm-0 ml-3 ml-sm-0"
              id="days"
              disabled={true}
            />
          </div>
        )}
        <div className="form-inline row">
          <span className="col-12 col-sm-3">Purpose Of Leave:</span>
          <input
            type="text"
            value={this.state.desc}
            onChange={this.handleChange.bind(this)}
            className=" form-control mb-2 mr-3 mr-sm-0 ml-3 ml-sm-0"
            id="purpose"
            placeholder="Enter"
            required
          />
        </div>
        <div className="form-inline row">
          <span className="col-12 col-sm-3">Leave Type:</span>
          <DropdownButton
            id="dropdown-basic-button"
            title={this.state.leaveType}
            className="ml-3 ml-sm-0"
          >
            <Dropdown.Item
              onClick={() => this.dataTypeChangeEvent("PL")}
              className=""
              id="annualLeave"
            >
              PL
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => this.dataTypeChangeEvent("LOP")}
              className=""
              id="sickLeave"
            >
              LOP
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => this.dataTypeChangeEvent("WFH")}
              className=""
              id="WFHLeave"
            >
              WFH
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="form-group mt-3">
          <div className="col-12">
            <Button variant="success" id="leaveBtn" type="submit">
              Apply
            </Button>
            <Button variant="primary" id="resetBtn" onClick={this.resetHandler}>
              Reset
            </Button>
          </div>
        </div>
        {!this.state.msgHide && (
          <>
            <div className="offset-3 col-sm-4">
              {
                this.props.errMessage ? (
                    
                    <p style={{color:'red'}}>
                      <b>{this.props.leaveApplyMessage}</b>
                    </p>
                ):(
                    <p>
                      <b>{this.props.leaveApplyMessage}</b>
                    </p>
                )
              }
              
            </div>
          </>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leaveApplyMessage: state.Leave.leaveApplyMessage,
    errMessage: state.Leave.errMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyLeave);
