import { createStore,applyMiddleware } from 'redux';
import rootReducer from "./../reducers";
import { httpMiddleware } from "./../middleware";
import thunk from 'redux-thunk';

const store = createStore(
  rootReducer,
  applyMiddleware(httpMiddleware,thunk));

  export default store;
