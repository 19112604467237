import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./attendance.css";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handleApiCall } from "./../../../Redux/actions/index";
import {
  LOGGEDIN_EMP_API,
  GET_ATTENDANCE_API,
  API_URL,
  MOCK_FLAG,
  GET_LEAVE_REPORT_API,
} from "./../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import store from "./../../../Redux/store";
import FileSaver from "file-saver";
import moment from "moment";

class AttendanceComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      successMsg: "",
      organisation: "MeConIt",
      isAttendenceForm: false,
    };
  }
  componentDidMount() {
    var form = document.getElementById("attendanceForm");
    form.classList.remove("was-validated");
    let user = localStorage.getItem("userID");
    store.dispatch(
      handleApiCall(LOGGEDIN_EMP_API, "GET", "GET_EMP", {}, user, MOCK_FLAG)
    );
  }

  handleFromDate = (date) => {
    this.setState({ fromDate: date, toDate: date, successMsg: "" });
  };

  handleToDate = (date) => {
    this.setState({ toDate: date, successMsg: "" });
  };

  getLeavesReport = (event) => {
    event.preventDefault();
    this.setState({ successMsg: "Wait..." });
    let obj = {
      organisation: this.state.organisation,
    };
    //store.dispatch(handleApiCall(GET_ATTENDANCE_API, 'POST', 'GET_ATTENDANCE', obj, '',false));
    var xhr = new XMLHttpRequest();
    xhr.open("POST", API_URL + GET_LEAVE_REPORT_API, true);
    xhr.setRequestHeader("Cache-Control", "no-cache");
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    xhr.responseType = "blob";
    xhr.onload = function (dispatch) {
      if (xhr.status === 200) {
        const month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const d = new Date();
        let name = month[d.getMonth()];
        let filename = `LeaveBalRecord_${this.state.organisation}_${name}`;
        FileSaver.saveAs(xhr.response, filename + ".pdf");
        this.setState({
          successMsg: "Leave Balance Report Fetched Successfully.",
        });
      }
    }.bind(this);
    xhr.send(JSON.stringify(obj));
  };

  getAttendance = (event) => {
    event.preventDefault();
    var form = document.getElementById("attendanceForm");
    if (form.checkValidity()) {
      this.setState({ successMsg: "Wait..." });
      form.classList.remove("was-validated");
      let obj = {
        organisation: this.state.organisation,
        fromMonth: moment(this.state.fromDate).format("MMM YYYY"),
        toMonth: moment(this.state.toDate).format("MMM YYYY"),
      };
      //store.dispatch(handleApiCall(GET_ATTENDANCE_API, 'POST', 'GET_ATTENDANCE', obj, '',false));
      var xhr = new XMLHttpRequest();
      xhr.open("POST", API_URL + GET_ATTENDANCE_API, true);
      xhr.setRequestHeader("Cache-Control", "no-cache");
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      // xhr.send(JSON.stringify(obj));
      xhr.responseType = "blob";
      xhr.onload = function (dispatch) {
        if (xhr.status === 200) {
          let filename =
            "Record_" +
            this.state.organisation +
            "_" +
            moment(this.state.fromDate).format("MMM YYYY") +
            "_to_" +
            moment(this.state.toDate).format("MMM YYYY");
          FileSaver.saveAs(xhr.response, filename + ".pdf");
          this.setState({
            successMsg: "Attendence Report Fetched Successfully.",
            isAttendenceForm: false
          });

        }
      }.bind(this);
      xhr.send(JSON.stringify(obj));
    } else {
      form.classList.add("was-validated");
    }
  };
  resetData = () => {
    var form = document.getElementById("attendanceForm");
    form.classList.remove("was-validated");
    this.setState({
      fromDate: null,
      toDate: null,
      successMsg: "",
      organisation: "MeConIt",
    });
  };

  formOptionChangeEvent = (value) => {
    this.setState({ organisation: value });
  };
  render() {
    return (
      <div>
        <div id="attendance">
          <h3>Reports</h3>
          <hr />
          <div className="attendanceDiv">
            <form id="attendanceForm">
              <div className="row">
                <div className="col-sm-2">
                  <b>
                    <span>Select organisation:</span>
                  </b>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={this.state.organisation}
                  >
                    <Dropdown.Item
                      onClick={() => this.formOptionChangeEvent("MeConIt")}
                      className=""
                      id="MeConIt"
                    >
                      MeConIt
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.formOptionChangeEvent("Hoch-Rein")}
                      className=""
                      id="Hoch-Rein"
                    >
                      Hoch-Rein
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <div className="row btnCss">
                <div className="offset-5 col-sm-12">
                  <Button
                    className="btn btn-primary mb-3 mb-sm-0"
                    type="submit"
                    onClick={() => this.setState({ isAttendenceForm: true})}
                  >
                    Attendence Report
                  </Button>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    onClick={this.getLeavesReport}
                  >
                    Leave Bal Report
                  </Button>
                </div>
              </div>
              {this.state.isAttendenceForm && (
                <>
                  <div className="row">
                    <div className="col-12 col-md-2 mb-3 mb-sm-0">
                      <b className="">
                        <span>From:</span>
                      </b>
                      <DatePicker
                        placeholderText="select date"
                        dateFormat="MMM yyyy"
                        showMonthYearPicker
                        selected={this.state.fromDate}
                        onChange={this.handleFromDate}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-2">
                      <b>
                        <span>To:</span>
                      </b>
                      <DatePicker
                        placeholderText="select date"
                        dateFormat="MMM yyyy"
                        showMonthYearPicker
                        minDate={this.state.fromDate}
                        selected={this.state.toDate}
                        onChange={this.handleToDate}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="row btnCss">
                    <Button
                      variant="primary"
                      id="resetBtn"
                      onClick={this.getAttendance}
                    >
                      Get Report
                    </Button>

                    <Button
                      variant="primary"
                      id="resetBtn"
                      onClick={this.resetData}
                    >
                      Reset
                    </Button>
                  </div>
                </>
              )}

              {this.state.successMsg !== "" && (
                <div className="row">
                  <div className="offset-5 col-sm-4">
                    <p>
                      <b>{this.state.successMsg}</b>
                    </p>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allEmp: state.Leave.allEmp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceComp);
