import { Comparator  } from 'react-bootstrap-table2-filter';

//export const API_URL = 'http://localhost:8080';
export const API_URL = 'http://meconit.in:8080/leave-app';
export const MOCK_URL = 'http://localhost:3333';
export const MOCK_FLAG = false;
// export const API_URL = 'http://192.168.0.105:8080';
export const LOGIN_API = '/login';
export const MANAGER_API = '/employees/manager';
export const HOLIDAY_LIST_API = '/holiday';
export const GENERATE_OTP_API = '/forgot_password/generate_otp';
export const RESET_PWD_API = '/forgot_password/reset_password';
export const ADD_HOLIDAY_API = '/holiday/add';
export const HR_REQUEST_LIST_API = '/hr_request';
export const HR_REQ_UPDATE_API = '/hr_request/update';
export const HR_REQ_APPLY_API = '/hr_request/apply';
export const LOGGEDIN_EMP_API = '/employees';
export const GET_LEAVES_API = '/leaves';
export const GET_NOTIFICATION_API = '/notification';
export const LEAVE_UPDATE_API = '/leaves/update';
export const MARK_ABSENT_API = '/leaves/mark_absent';
export const LEAVE_APPLY_API = '/leaves/apply';
export const LOGOUT_API = '/logout';
export const MARK_READ_NOTIFY_API = '/notification/mark_read';
export const ADD_NEWS_API = '/news/add';
export const EDIT_PROFILE_API = '/employees/edit';
export const ADD_USER_API = '/employees/add';
export const GET_UPCOMING_EVT_API = '/holiday/upcoming_events';
export const PUSH_NOTIFICATION_API = '/push_notification';
export const DELETE_EMPLOYEE_API = '/employees/remove';
export const GET_ATTENDANCE_API = '/leaves/report/pdf';
export const GET_LEAVE_REPORT_API = '/leaves/report/balance';
export const VERIFY_TOKEN_API = '/verify_token';
export const HR_SEND_QUERY_API = '/hr_request/query';


/*------Api dispatch types--------*/
export const REQUESTED = "_REQUESTED";
export const SUCCESS = "_SUCCESS";
export const FAILURE = "_FAILURE";


/*------Card Messages-------------*/
export const LEAVE_APP_DESC = 'You can Manage and track your leaves. Receive Notifications regarding important Announcements.'
export const HR_APP_DESC = 'Interact with HR, ask your query/ apply for stationary needs and also track the status of applications.'


 //HTTP Action
export const HTTP_ACTION = "HTTP_ACTION";


/*----------filter leave table rows custom function----------*/

export function getFilteredRows(tableRows, filters) {
  const result = tableRows.filter((row) => {
    let valid = true;
    /*---get loop on the filter object---*/
    for (const dataField in filters) {

      const { filterVal, filterType, comparator } = filters[dataField];

      /*---check the type of filter mentioned in columns arr in Bootstrap table---*/

        if (filterType === 'TEXT') {
        if (comparator === Comparator.LIKE) {

          /*------since name is inside the employee object
          so fetching the value as below otherwise return undefined.
          Also converting filter and row value to uppercase to match
          case insensitive filter results.------*/

          if (dataField === "employee.name") {
            valid = row["employee"]["name"].toString().toUpperCase().indexOf(filterVal.toUpperCase()) > -1;
          }
          else {
            valid = row[dataField].toString().toUpperCase().indexOf(filterVal.toUpperCase()) > -1;
          }

        } else {
          valid = row[dataField] === filterVal;
        }
      }
      if (!valid) break;
    }
    return valid;
  });
  return result;
}
