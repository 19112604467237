import React from 'react';
import './../Leave-Comp/leaves.css';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from 'react-loader-spinner';
import { Button, } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {handleApiCall} from './../../../Redux/actions/index';
import {GET_LEAVES_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';


class LeaveActions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      notificationArr:[],
      leaveUpdateMsg:''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.notificationArr !== this.state.notificationArr) {
      let obj = {
        fromDate:moment().startOf('year').format('DD-MM-YYYY'),
        toDate:moment().endOf('year').format('DD-MM-YYYY')
      }
      let user = localStorage.getItem('userID');
      store.dispatch(handleApiCall(GET_LEAVES_API, 'POST', 'GET_LEAVES', obj, user,MOCK_FLAG));
    }
    else if (prevState.leaveUpdateMsg !== this.state.leaveUpdateMsg) {
      if (this.state.leaveUpdateMsg === 'Leave Updated Successfully') {
        let obj = {
          fromDate:moment().startOf('year').format('DD-MM-YYYY'),
          toDate:moment().endOf('year').format('DD-MM-YYYY')
        }
        let user = localStorage.getItem('userID');
        store.dispatch(handleApiCall(GET_LEAVES_API, 'POST', 'GET_LEAVES', obj, user,MOCK_FLAG));
      }
    }
  }

  static getDerivedStateFromProps(props, state){
    if (props.notificationArr !== state.notificationArr) {
      return {
        notificationArr: props.notificationArr
      }
    }
    if (props.leaveUpdateMsg !== state.leaveUpdateMsg) {
      return {
        leaveUpdateMsg: props.leaveUpdateMsg
      }
    }
    // Return null to indicate no change to state.
    return null;
  }

  render() {
    let histIndex = 0;
    let pendIndex = 0;

  return (
    <Table striped bordered hover>
    <thead>
    <tr>
    <th>#</th>
    {this.props.selectedTab === 'Pending' && <th>Employee Name</th>}
    <th>Leave Type</th>
    <th>From</th>
    <th>To</th>
    <th>No Of Days</th>
    <th>Purpose</th>
    <th>Status</th>
    <th>Action</th>
    </tr>
    </thead>
    {this.props.selectedTab === 'Pending' &&
    <tbody>
    {/*-----Leave Pending Actions Tab Table------*/}
    {(this.props.status === 'Approved' || this.props.status === 'Rejected') && this.props.leaveUpdateMsg === 'Wait...' &&
      <div className="leaveLoader">
        <div className="loader-outer">
        <div className="loader-inner">
    <Loader
      type="Oval"
      color="white"
      height={50}
      width={50}

      //4 secs

    /> </div></div> </div>
    }
    {this.props.leaveList.length > 0 && this.props.leaveList.map((item, index) => {
          if (item.leaveStatus === 'Pending') {
            if (item.employee.emp_Id !== this.props.userInfo.emp_Id) {
              pendIndex += 1;
              return (
                <tr>
                  <td>{pendIndex}</td>
                  <td title={item.employee.email}>{item.employee.name}</td>
                  <td>{item.leaveType}</td>
                  <td>{item.fromDate}</td>
                  <td>{item.toDate}</td>
                  <td>{item.noOfDays} { item.halfDay && `(${item.halfDayType})`}</td>
                  <td>{item.leavePurpose}</td>
                  <td>{item.leaveStatus}</td>
                  <td>
                    <Button variant="success" id="acptLeave" onClick={() => this.props.confirmModal('Approved', item.leave_Id)}>Approve</Button>
                    <Button variant="danger" id="rejLeave" onClick={() => this.props.confirmModal('Rejected', item.leave_Id)}>Reject</Button>
                  </td>
                </tr>
              );
            }
          }
        })
      }
</tbody>}
{this.props.selectedTab === 'History' &&
<tbody>
  {/*-----Leave History Tab Table------*/}
  {(this.props.status === 'Cancelled') && this.props.leaveUpdateMsg === 'Wait...' &&
    <div className="leaveLoader">
      <div className="loader-outer">
      <div className="loader-inner">
  <Loader
    type="Oval"
    color="white"
    height={50}
    width={50}

    //4 secs

  /> </div></div> </div>
  }
  {this.props.leaveList.length > 0 && this.props.leaveList.map((item, index) => {
          if (item.employee.emp_Id === this.props.userInfo.emp_Id) {
            histIndex += 1;
            return (
              <tr>
                <td>{histIndex}</td>
                <td>{item.leaveType}</td>
                <td>{item.fromDate}</td>
                <td>{item.toDate}</td>
                <td>{item.noOfDays} { item.halfDay && `(${item.halfDayType})`}</td>
                <td>{item.leavePurpose}</td>
                <td>{item.leaveStatus}</td>
                  <td>
                    {(item.leaveStatus === 'Pending' || item.leaveStatus === 'Approved') && (moment(item.toDate, 'DD-MM-YYYY').toDate() > moment().toDate()) &&<Button variant="success" id="cancelLeave" onClick={() => this.props.confirmModal('Cancelled', item.leave_Id)}>Cancel</Button>}
                  </td>
              </tr>
            );
          }
      })
    }
</tbody>}


    </Table>
  )
}
}


const mapStateToProps = (state) => {
    return {
        notificationArr: state.Leave.notificationArr,
        leaveUpdateMsg: state.Leave.leaveUpdateMsg,
        leaveList:state.Leave.leaveApplicaton,
        userInfo:state.Leave.userInfo
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaveActions);
