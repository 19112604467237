import React, { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { CommonActions } from "./../../../Redux/actions/CommonActions";
import { Button, Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { getFilteredRows } from "./../../../utils";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../../css/dashboard.css";
import Chart from "react-google-charts";
import NotificationSystem from "react-notification-system";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import newSteps from "./../../Common/User-Tour/steps";
import store from "./../../../Redux/store";
import { handleApiCall } from "./../../../Redux/actions/index";
import {
  LOGGEDIN_EMP_API,
  GET_LEAVES_API,
  GET_NOTIFICATION_API,
  GET_UPCOMING_EVT_API,
  MOCK_FLAG,
} from "./../../../utils";
import moment from "moment";
import Loader from "react-loader-spinner";

let newscount = 0;
const ranges = {
  thisYear: [
    moment().startOf("year").format("DD-MM-YYYY"),
    moment().endOf("year").format("DD-MM-YYYY"),
  ],
  thisMonth: [
    moment().startOf("month").format("DD-MM-YYYY"),
    moment().endOf("month").format("DD-MM-YYYY"),
  ],
  lastMonth: [
    moment().subtract(1, "months").startOf("month").format("DD-MM-YYYY"),
    moment().subtract(1, "months").endOf("month").format("DD-MM-YYYY"),
  ],
  lastSixMonth: [
    moment().subtract(6, "months").startOf("month").format("DD-MM-YYYY"),
    moment().subtract(1, "months").endOf("month").format("DD-MM-YYYY"),
  ],
  LastYear: [
    moment().subtract(1, "year").startOf("year").format("DD-MM-YYYY"),
    moment().subtract(1, "year").endOf("year").format("DD-MM-YYYY"),
  ],
};

var tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPage: "Home",
      dateSelected: "This Year",
      chartTitle: "All Data",
      graphDataArr: [],
      filterData: [],
      count: 0,
      waitList: 0,
      userChartId: "",
      userChartName: "",
      filters: {},
      showIcon: true,
      showUpcomingIcon: true,
      start: "",
      end: "",
      alertsArr: [],
      randomNo: 0,
      filterChartFlag: false,
    };
  }
  componentDidMount() {
    let user = localStorage.getItem("userID");
    let obj = {
      fromDate: moment().startOf("year").format("DD-MM-YYYY"),
      toDate: moment().endOf("year").format("DD-MM-YYYY"),
    };
    store.dispatch(
      handleApiCall(LOGGEDIN_EMP_API, "GET", "GET_EMP", {}, user, MOCK_FLAG)
    );
    store.dispatch(
      handleApiCall(GET_LEAVES_API, "POST", "GET_LEAVES", obj, user, MOCK_FLAG)
    );
    store.dispatch(
      handleApiCall(
        GET_NOTIFICATION_API,
        "GET",
        "GET_ALERTS",
        {},
        user,
        MOCK_FLAG
      )
    );
    store.dispatch(
      handleApiCall(
        GET_UPCOMING_EVT_API,
        "GET",
        "GET_EVENTS",
        {},
        "",
        MOCK_FLAG
      )
    );
    this.setState({
      start: moment().startOf("year").format("DD-MM-YYYY"),
      end: moment().endOf("year").format("DD-MM-YYYY"),
    });
    this.notificationSystem = React.createRef();
    this.upcomingEventSystem = React.createRef();
    this.setState({ filterData: this.props.leaveList });
    //this.setState({graphDataArr: this.props.leaveList});
    this.setState({ count: this.props.leaveList.length });
    this.setState({ alertsArr: this.props.alertsArr });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.unreadMsg !== prevProps.unreadMsg) {
      newscount = this.props.unreadMsg;
    }
    if (prevState.randomNo !== this.state.randomNo) {
      let user = localStorage.getItem("userID");
      let obj = {
        fromDate: this.state.start,
        toDate: this.state.end,
      };
      store.dispatch(
        handleApiCall(LOGGEDIN_EMP_API, "GET", "GET_EMP", {}, user, MOCK_FLAG)
      );
      store.dispatch(
        handleApiCall(
          GET_LEAVES_API,
          "POST",
          "GET_LEAVES",
          obj,
          user,
          MOCK_FLAG
        )
      );
      //store.dispatch(handleApiCall(GET_NOTIFICATION_API, 'GET', 'GET_ALERTS', {}, user,MOCK_FLAG));
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.randomNo !== state.randomNo) {
      return {
        randomNo: props.randomNo,
      };
    }
    if (props.alertsArr !== state.alertsArr) {
      return {
        alertsArr: props.alertsArr,
      };
    }
    if (state.filterData !== props.leaveList) {
      let filters = { ...state.filters };
      const result = getFilteredRows(props.leaveList, filters);
      return {
        graphDataArr: result,
        filterData: result,
        count: result.length,
      };
    }

    return null;
  }
  userHandler = (id, value) => {
    this.setState({ userChartId: id });
    this.setState({ userChartName: value });
  };

  dateHandler = (name, rangeId) => { 
    var start = ranges[rangeId][0];
    var end = ranges[rangeId][1];
    this.setState({
      start,
      end
    });
    let user = localStorage.getItem("userID");
    let obj = {
      fromDate: start,
      toDate: end,
    };
    store.dispatch(
      handleApiCall(GET_LEAVES_API, "POST", "GET_LEAVES", obj, user, MOCK_FLAG)
    );
    this.setState({ dateSelected: name });
  };

  handleChange = (event) => {
    this.setState({ searchParam: event.target.value });
  };
  handleTableChange = (type, { filters }) => {
    let chartFlag = false;
    for (const dataField in filters) {
      if (dataField === "employee.name") {
        chartFlag = true;
      }
    }

    this.setState({ filters: filters, filterChartFlag: chartFlag });
    const result = getFilteredRows(this.props.leaveList, filters);
    this.setState(() => ({
      filterData: result,
      graphDataArr: result,
      count: result.length,
    }));
  };
  handleDataChange = ({ dataSize }) => {
    this.setState({ graphDataArr: this.node.filterContext.data });
    this.setState({ count: dataSize });
  };
  removeNotification = (notification) => {
    this.setState({ showIcon: true });
  };
  removeUpcomingEvtNotification = (notification) => {
    this.setState({ showUpcomingIcon: true });
  };
  showUpcomingEvents = (event) => {
    this.setState({
      showUpcomingIcon: false,
    });
    const notification = this.upcomingEventSystem.current;
    notification.addNotification({
      onRemove: this.removeUpcomingEvtNotification,
      message: "Upcoming Events",
      level: "info",
      autoDismiss: 0,
      children: (
        <div>
          {this.props.upcomingEvtArr.map((item) => {
            return (
              <div className="newsDiv">
                <hr />
                <p>
                  <b>Country: </b>
                  {item.country}
                </p>
                <p>
                  <b>Day: </b>
                  {item.eventDate}
                </p>
                <p>
                  <b>Event: </b>
                  {item.eventName}
                </p>
              </div>
            );
          })}
        </div>
      ),
    });
  };
  addNotification = (event) => {
    this.setState({
      showIcon: false,
    });
    const notification = this.notificationSystem.current;
    let Arr = [];
    notification.addNotification({
      onRemove: this.removeNotification,
      message: "Alerts",
      level: "info",
      autoDismiss: 0,
      children: (
        <div>
          {this.props.alertsArr.map((item) => {
            if (item.news === true) {
              newscount = 0;
              let news = "newsCount_" + localStorage.getItem("userID");
              Arr.push(item.notificationId);
              localStorage.setItem(news, JSON.stringify(Arr));
              return (
                <div className="newsDiv">
                  <hr />
                  <p>
                    <b>Time: </b>
                    {moment
                      .utc(item.createdOn, "DD-MM-YYYY HH:mm:ss")
                      .local()
                      .format("DD-MM-YYYY HH:mm:ss")}
                    .
                  </p>
                  <p>
                    <b>News: </b>
                    {item.notificationInfo}
                  </p>
                </div>
              );
            } else {
              return (
                <div className="notifyDiv">
                  <hr />
                  <p>
                    <b>Time: </b>
                    {moment
                      .utc(item.createdOn, "DD-MM-YYYY HH:mm:ss")
                      .local()
                      .format("DD-MM-YYYY HH:mm:ss")}
                    .
                  </p>
                  <p>
                    <b>Notification: </b>
                    {item.notificationInfo}
                  </p>
                </div>
              );
            }
          })}
        </div>
      ),
    });
  };
  closeModal = (evt) => {
    this.props.setTourFlag(false);
  };

  render() {
    console.log(this.state.filterData);
    const columns = [
      localStorage.getItem("role") !== "Employee"
        ? {
            dataField: "employee.name",
            text: "User Name",
            filter: textFilter(),
            sort: true,
          }
        : {},
      {
        dataField: "leaveType",
        text: "Leave Type",
        filter: textFilter(),
        sort: true,
      },
      {
        dataField: "fromDate",
        text: "From",
        filter: textFilter(),
        sort: true,
      },
      {
        dataField: "toDate",
        text: "To",
        filter: textFilter(),
        sort: true,
      },
      {
        dataField: "noOfDays",
        text: "No of days",
        filter: textFilter(),
        sort: true,
      },
      {
        dataField: "leavePurpose",
        text: "Purpose",
        filter: textFilter(),
        sort: true,
      },
      {
        dataField: "leaveStatus",
        text: "Status",
        filter: textFilter(),
        sort: true,
      },
      {
        dataField: "statusReason",
        text: "Status Reason",
        filter: textFilter(),
        sort: true,
      },
    ];
    // var test1 = ['Mark', '23 Jan 2020', 1, 5];
    // var test2 = ['Akash', '02 Feb 2020', 8, 3];
    var dataArr = [];
    let noofdaysPL = 0;
    let noofdaysLOP = 0;
    let noofdaysWFH = 0;
    if (localStorage.getItem("role") === "Employee") {
      dataArr = [
        [
          "Leave",
          "Days",
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ];
      this.state.graphDataArr.forEach((item, index) => {
        if (item.leaveStatus === "Approved" || item.leaveStatus === "Absent") {
          if (item.leaveType === "PL") {
            noofdaysPL += item.noOfDays;
          } else if (item.leaveType === "WFH") {
            noofdaysWFH += item.noOfDays;
          } else {
            noofdaysLOP += item.noOfDays;
          }
        }
      });
      var array1 = [
        "PL",
        noofdaysPL,
        '<div class="chartTooltip"><b>PL</b>' +
          "<div><b>" +
          noofdaysPL +
          "</b></div>",
      ];
      var array2 = [
        "LOP",
        noofdaysLOP,
        '<div class="chartTooltip"><b>LOP</b>' +
          "<div><b>" +
          noofdaysLOP +
          "</b></div>",
      ];
      var array3 = [
        "WFH",
        noofdaysWFH,
        '<div class="chartTooltip"><b>WFH</b>' +
          "<div><b>" +
          noofdaysWFH +
          "</b></div>",
      ];
      dataArr.push(array1, array2, array3);
    } else {
      if (!this.state.filterChartFlag) {
        var empArr = [];
        dataArr = [
          [
            "Leave",
            "Days",
            { role: "tooltip", type: "string", p: { html: true } },
          ],
        ];
        this.props.allEmp.forEach((item) => {
          let obj = { user: item.name, PL: 0, LOP: 0, WFH: 0 };
          empArr.push(obj);
        });

        this.state.graphDataArr.forEach((item) => {
          for (var i = 0; i < empArr.length; i++) {
            if (item.employee.name === empArr[i].user) {
              if (
                item.leaveStatus === "Approved" ||
                item.leaveStatus === "Absent"
              ) {
                if (item.leaveType === "LOP") {
                  empArr[i].LOP += item.noOfDays;
                } else if (item.leaveType === "WFH") {
                  empArr[i].WFH += item.noOfDays;
                } else {
                  empArr[i].PL += item.noOfDays;
                }
              }
            }
          }
        });
        empArr.forEach((item) => {
          let leave = item.PL + item.LOP + item.WFH;
          let tooltip =
            '<div class="chartTooltip"><b>' + item.user + "</b></div>";
          if (item.PL !== 0) {
            tooltip +=
              '<div class="chartTooltip"><b>PL:' + item.PL + "</b></div>";
          }
          if (item.LOP !== 0) {
            tooltip +=
              '<div class="chartTooltip"><b>LOP:' + item.LOP + "</b></div>";
          }
          if (item.WFH !== 0) {
            tooltip +=
              '<div class="chartTooltip"><b>WFH:' + item.WFH + "</b></div>";
          }
          var arr1 = [item.user, leave, tooltip];
          dataArr.push(arr1);
        });
      } else {
        dataArr = [
          [
            "Leave",
            "Days",
            { role: "tooltip", type: "string", p: { html: true } },
          ],
        ];
        this.state.graphDataArr.forEach((item, index) => {
          if (
            item.leaveStatus === "Approved" ||
            item.leaveStatus === "Absent"
          ) {
            if (item.leaveType === "PL") {
              noofdaysPL += item.noOfDays;
            } else if (item.leaveType === "WFH") {
              noofdaysWFH += item.noOfDays;
            } else {
              noofdaysLOP += item.noOfDays;
            }
          }
        });
        var arr1 = [
          "PL",
          noofdaysPL,
          '<div class="chartTooltip"><b>PL</b>' +
            "<div><b>" +
            noofdaysPL +
            "</b></div>",
        ];
        var arr2 = [
          "LOP",
          noofdaysLOP,
          '<div class="chartTooltip"><b>LOP</b>' +
            "<div><b>" +
            noofdaysLOP +
            "</b></div>",
        ];
        var arr3 = [
          "WFH",
          noofdaysWFH,
          '<div class="chartTooltip"><b>WFH</b>' +
            "<div><b>" +
            noofdaysWFH +
            "</b></div>",
        ];
        dataArr.push(arr1, arr2, arr3);
      }
    }
    var filterColumnIndex = 0;
    let username = localStorage.getItem("name");
    return (
      <div id="homeForm">
        {this.state.showIcon && (
          <button
            type="button"
            className="newsIcon btn btn-info"
            onClick={this.addNotification}
          >
            {"Alerts(" + newscount + ")"}
          </button>
        )}
        {this.state.showUpcomingIcon && (
          <button
            type="button"
            className="upcomingEvtIcon btn btn-info"
            onClick={this.showUpcomingEvents}
          >
            Upcoming Events
          </button>
        )}
        <div id="upcomingEvtModal">
          <NotificationSystem ref={this.upcomingEventSystem} />
        </div>
        <NotificationSystem ref={this.notificationSystem} />

        <div className="container-fluid">
          <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
            <Modal show={this.props.tourFlag} id="modalCss">
              <Modal.Header>
                <Modal.Title>Welcome {username}!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6>
                  <b>
                    This is your first visit to the website, we request you to
                    take a tour to know the features.
                  </b>
                </h6>
              </Modal.Body>
              <Modal.Footer>
                <div onClick={this.closeModal}>
                  <Content />
                </div>
              </Modal.Footer>
            </Modal>

            {localStorage.getItem("role") !== "Admin" &&
              localStorage.getItem("role") !== "MD" && (
                <div className="row rowCss">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="square1" id="Popover1">
                      <span>Leave Balance</span>

                      <p>{this.props.userInfo.leave_balance}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="square2" id="Popover2">
                      <span>Leaves Taken</span>
                      <p>{this.props.userInfo.leave_taken}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="square3" id="Popover3">
                      <span>LOP</span>
                      <p>{this.props.userInfo.lop}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="square4" id="Popover4">
                      <span>Pending Applications</span>
                      <p>{this.props.userInfo.pending_approval_leaves}</p>
                    </div>
                  </div>
                </div>
              )}
            <div className="chartCss">
              <div
                className={`row bottomRowCss ${localStorage.getItem("role")}`}
              >
                <div className="col-12 col-lg-9 recentForm">
                  <h5>Recent Leave Applications</h5>
                  <hr />
                  <div className="form-inline" id="filterMonth">
                    <DropdownButton
                      id="dateDropdown"
                      title={this.state.dateSelected}
                    >
                      <Dropdown.Item
                        onClick={() =>
                          this.dateHandler("This Year", "thisYear")
                        }
                        className=""
                        id="thisYear"
                      >
                        This Year
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.dateHandler("This Month", "thisMonth")
                        }
                        className=""
                        id="thisMonth"
                      >
                        This Month
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.dateHandler("Last Month", "lastMonth")
                        }
                        className=""
                        id="lastMonth"
                      >
                        Last Month
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.dateHandler("Last 6 Months", "lastSixMonth")
                        }
                        className=""
                        id="lastSixMonth"
                      >
                        Last 6 Months
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          this.dateHandler("Last Year", "LastYear")
                        }
                        className=""
                        id="LastYear"
                      >
                        Last Year
                      </Dropdown.Item>
                    </DropdownButton>
                    <b>
                      Records: <span className="badge">{this.state.count}</span>
                    </b>
                  </div>
                  {this.props.btnFlag && (
                    <div className="row home-Loader">
                      <div className="loader-outer">
                        <div className="loader-inner">
                          <Loader
                            type="Oval"
                            color="white"
                            height={50}
                            width={50}

                            //4 secs
                          />{" "}
                        </div>
                      </div>{" "}
                    </div>
                  )} 
                  <div style={{ width: "100%" }}>
                    <BootstrapTable
                      remote={{ filter: true }}
                      onTableChange={this.handleTableChange}
                      ref={(n) => (this.node = n)}
                      onDataSizeChange={this.handleDataChange}
                      keyField="leave_Id"  
                      data={this.state.filterData}
                      columns={columns}
                      filter={filterFactory()}
                      wrapperClasses="homeTableBody"
                    />
                  </div>
                </div>
                <div className="chartDiv col-12 col-lg-3">
                  <Chart
                    width={300}
                    height={315}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={dataArr}
                    options={{
                      legend: "none",
                      chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                      pieSliceText: "label",
                      tooltip: { isHtml: true, trigger: "visible" },
                    }}
                    chartPackages={["corechart", "controls"]}
                    controls={[
                      {
                        controlEvents: [
                          {
                            eventName: "statechange",
                            callback: ({ chartWrapper, controlWrapper }) => {},
                          },
                        ],
                        controlType: "CategoryFilter",
                        options: {
                          filterColumnIndex: filterColumnIndex,
                          ui: {
                            labelStacking: "vertical",
                            label: "Leave graphical representation:",
                            allowTyping: false,
                            allowMultiple: false,
                          },
                        },
                      },
                    ]}
                  />
                </div>
                {/*
      {this.props.userInfo.role !== 'Employee' &&
    <DropdownButton id="chartDropdown" title={this.state.userChartName}>
      {this.props.allEmp.length > 0 && this.props.allEmp.map((item) => {
        return (
          <Dropdown.Item onClick={() => this.userHandler(item.emp_Id, item.first_name)} className="" id={item.first_name}>{item.first_name}</Dropdown.Item>
        )
      })
      }
    </DropdownButton>}
  */}
              </div>

              {/*<DropdownButton id="dateDropdown" title={this.state.chartTitle}>
      <Dropdown.Item onClick={() => this.chartDateHandler('All Data', 'allData')} className="" id="allData">All Data</Dropdown.Item>
      <Dropdown.Item onClick={() => this.chartDateHandler('This Month', 'thisMonth')} className="" id="thisMonth">This Month</Dropdown.Item>
      <Dropdown.Item onClick={() => this.chartDateHandler('Last Month', 'lastMonth')} className="" id="lastMonth">Last Month</Dropdown.Item>
      <Dropdown.Item onClick={() => this.chartDateHandler('Last 6 Months', 'lastSixMonth')} className="" id="lastSixMonth">Last 6 Months</Dropdown.Item>
      <Dropdown.Item onClick={() => this.chartDateHandler('Last 1 Year', 'LastOneYear')} className="" id="LastOneYear">Last 1 Year</Dropdown.Item>
    </DropdownButton>*/}
            </div>
          </ShepherdTour>
        </div>
      </div>
    );
  }
}

function cancelTour() {
  let userTour = "TourID_" + localStorage.getItem("userID");
  localStorage.setItem(userTour, false);
}
function Content() {
  const tour = useContext(ShepherdTourContext);

  return (
    <div>
      <Button onClick={tour.start} className="tourBtn">
        Start Tour
      </Button>
      <Button onClick={cancelTour} className="tourBtn">
        Cancel Tour
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Leave.userInfo,
    leaveList: state.Leave.leaveApplicaton,
    allEmp: state.Leave.allEmp,
    alertsArr: state.Leave.alertsArr,
    randomNo: state.Leave.randomNo,
    upcomingEvtArr: state.Leave.upcomingEvtArr,
    tourFlag: state.Leave.tourFlag,
    unreadMsg: state.Leave.unreadMsg,
    btnFlag: state.Leave.btnFlag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      leaveHandler: CommonActions.leaveHandler,
      setTourFlag: CommonActions.setTourFlag,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
