import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LandingScreenImg from "./../../../images/LandingScreenImg.png";
import cardIcon from "./../../../images/cardIcon.png";
import { CommonActions } from "./../../../Redux/actions/CommonActions";
import { LEAVE_APP_DESC, HR_APP_DESC } from "./../../../utils";
import "./LandingScreen.css";
import { Redirect } from "react-router-dom";

class LandingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApp: "",
    };
  }
  componentDidMount() {}
  setApp = (value) => {
    if (value === "Leave") {
      let userTour = "TourID_" + localStorage.getItem("userID");
      if (localStorage.getItem(userTour) === null) {
        this.props.setTourFlag(true);
      } else {
        this.props.setTourFlag(false);
      }
    }
    this.setState({ selectedApp: value });
    this.props.setApp(value);
  };
  render() {
    if (this.state.selectedApp === "Hr") {
      if (localStorage.getItem("role") === "Admin") {
        return <Redirect to="/applications" />;
      } else {
        return <Redirect to="/Raise-request" />;
      }
    }
    if (this.state.selectedApp === "Leave") {
      return <Redirect to="/home" />;
    }

    return (
      <div>
        <div className="outerContainer">
          {/* <img
            className="bodyImg"
            src={LandingScreenImg}
            alt="Background"
          ></img> */}
          <div className="d-flex flex-direction-row justify-content-center align-items-center h-100 row">
            {/* <div className="col-12 col-md-6 d-flex justify-content-center"> </div> */}
            <div className=" col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
              <Card className="card1">
                <Card.Img variant="top" src={cardIcon} />
                <Card.Body>
                  <Card.Text style={{textAlign:'center'}}>{LEAVE_APP_DESC}</Card.Text>
                 
                    <Button
                      variant="success"
                      id="tileBtn"
                      onClick={() => this.setApp("Leave")}
                    >
                      LEAVE APP
                    </Button>
                
                </Card.Body>
              </Card>
            </div>
            <div className=" col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
              <Card className="card2">
                <Card.Img variant="top" src={cardIcon} />
                <Card.Body>
                  <Card.Text style={{textAlign:'center'}}>{HR_APP_DESC}</Card.Text>
                  <Button
                    variant="success"
                    id="tileBtn"
                    onClick={() => this.setApp("Hr")}
                  >
                    HR APP
                  </Button>
                </Card.Body>
              </Card>
            </div>

            {/* <div className="card1 mr-5">
                <img
                  className="card-img-bottom"
                  src={cardIcon}
                  alt="Card Icon"
                  style={{ width: "100%" }}
                />
                <div className="card-body">
                  
                  <p className="card-text">{LEAVE_APP_DESC}</p>
                  <Button
                    variant="success"
                    id="tileBtn"
                    onClick={() => this.setApp("Leave")}
                  >
                    LEAVE APP
                  </Button>
                </div>
              </div> */}

            {/* <div className="col-12 col-sm-6 d-flex justify-content-center mt-4 mt-md-0">  </div>*/}

            {/* <div className="card2 ">
                <img
                  className="card-img-bottom"
                  src={cardIcon}
                  alt="Card Icon"
                  style={{ width: "100%" }}
                />
                <div className="card-body">
                  
                  <p className="card-text">{HR_APP_DESC}</p>
                  <Button
                    variant="success"
                    id="tileBtn"
                    onClick={() => this.setApp("Hr")}
                  >
                    HR APP
                  </Button>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setApp: CommonActions.setApp,
      setTourFlag: CommonActions.setTourFlag,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingComponent);
