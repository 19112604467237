let initialState = {
  userInfo:'',
  loginRandonNo:0,
  leaveApplicaton:[],
  managerList:[],
  allEmp:[],
  holidayList:[],
  newsList:[],
  notificationArr:[],
  loginMsg:'',
  addEmpMsg:'',
  profileMessage:'',
  leaveApplyMessage:'',
  errMessage: false,
  markAbsentMessage:'',
  addNewsMsg:'',
  addHolidayMsg:'',
  otpMsg:'',
  otpFlag:false,
  resetMsg:'',
  resetFlag:false,
  alertsArr:[],
  randomNo:0,
  openNotification:[],
  onLoginShowNotify:false,
  selectedApp:'',
  leaveUpdateMsg:'',
  upcomingEvtArr:[],
  tourFlag: false,
  tokenMsg:'',
  unreadMsg:0,
  btnFlag: false
}

    export function getUserInfo(allEmployeeData, userInfo) {
      let obj = Object.assign({}, userInfo);
      allEmployeeData.forEach((item) =>{
        if (item.emp_Id == localStorage.getItem('userID')) {
          localStorage.setItem('name', item.name);
          obj = {...item};
        }
      });

      return obj;
    }

    export function showNotification(notificationArr, openNotification){
      let obj = [];
      let Arr = [];
      openNotification.forEach((item) =>{
          Arr.push(item.notificationId);
          // if (notificationArr[0].notificationId !== item.notificationId) {
          //   obj.push(item);
          // }
      });
      if (Arr.indexOf(notificationArr[0].notificationId) === -1) {
        obj.push(notificationArr[0]);
      }
      return obj;
    }
    export function displayNotify(notificationArr, data){
      let obj = [];

      data.forEach((item) =>{
          if (item.news === false) {
            if (item.read === false) {
              var dataObj = {
                //'createdOn': moment.utc(item.createdOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss'),
                'createdOn': item.createdOn,
                'news': item.news,
                'notificationId': item.notificationId,
                'notificationInfo': item.notificationInfo,
                'read': item.read
              }
              obj.push(dataObj);
            }
          }

      });

      return obj;
    }
    export function addData(newNotification, openNotification){
      let obj = Object.assign([], openNotification);
      newNotification.forEach((item) =>{
        obj.push(item);
      });

      return obj;
    }

    export function getUnreadMsg(data){
      let obj = Object.assign([], data);
      let news = 'newsCount_'+localStorage.getItem('userID');
      if (localStorage.getItem(news) == null) {
        localStorage.setItem(news, JSON.stringify([]));
      }
      let count = 0;
      obj.forEach((item) =>{
        if (item.news === true) {
          let pushArr = localStorage.getItem(news);
          if (pushArr.indexOf(item.notificationId) === -1) {
            count += 1;
          }
        }
      });

      return count;
    }

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUESTED':
        return {
          ...state,
          loginMsg: 'Wait...'
        }
    case 'LOGIN_SUCCESS':
        localStorage.setItem('userID',action.response.data.data.emp_Id);
        localStorage.setItem('role',action.response.data.data.role);
        localStorage.setItem('name',action.response.data.data.name);
        localStorage.setItem('organisation', action.response.data.data.organisation);
        localStorage.setItem('token', action.response.data.token);
        return {
          ...state,
          loginRandonNo:Math.floor((Math.random() * 1000000) + 1),
          userInfo: action.response.data.data,
          loginMsg:action.response.data.msg,
          randomNo:0,
          onLoginShowNotify:false
        };
    case 'LOGIN_FAILURE':
        return {
          ...state,
          loginMsg:action.msg
        }
    case 'LEAVE_ACTION_REQUESTED':
        return {
          ...state,
          leaveUpdateMsg:'Wait...'
        }
    case 'LEAVE_LIST':
        return {
          ...state
        }
    case 'LEAVE_STATUS':
        return {
          ...state

        }
    case 'MARK_ABSENT_REQUESTED':
        return {
          ...state,
          markAbsentMessage: 'Wait...'
        }
    case 'MARK_ABSENT_SUCCESS':
        return {
          ...state,
          markAbsentMessage: action.response.data.msg
        }
    case 'MARK_ABSENT_FAILURE':
        return {
          ...state,
          markAbsentMessage: action.msg
        }
    case 'GET_EMP_SUCCESS':
        return {
          ...state,
          userInfo: getUserInfo(action.response.data.data, state.userInfo),
          allEmp: action.response.data.data
        }
    case 'All_EMP_SUCCESS':
        return {
          ...state
        }
    case 'GET_LEAVES_REQUESTED':
        return {
          ...state,
          btnFlag:true
        }
    case 'GET_LEAVES_SUCCESS':
        return {
          ...state,
          btnFlag:false,
          errMessage: false,
          leaveApplicaton:action.response.data.data
        }
    case 'GET_LEAVES_FAILURE':
        return {
          ...state,
          btnFlag:false
        }
    case 'LEAVE_APPLY_FAILURE':
        return {
          ...state,
          leaveApplyMessage: action.msg,
          errMessage: true
        }
    case 'LOGOUT_SUCCESS':
    localStorage.removeItem('userID');
    localStorage.removeItem('role');
    localStorage.removeItem('selectedApp');
    localStorage.removeItem('name');
    localStorage.removeItem('organisation');
    localStorage.removeItem('token');
    //window.location.reload();
        return {
          ...state,
          notificationArr:[],
          loginRandonNo:Math.floor((Math.random() * 1000000) + 1),
          tokenMsg:''
        }
    case 'ADD_USER_REQUESTED':
        return {
          ...state,
          addEmpMsg:'Wait...'
        }
    case 'ADD_USER_SUCCESS':
        return {
          ...state,
          addEmpMsg:action.response.data.msg
        }
    case 'ADD_USER_FAILURE':
        return {
          ...state,
          addEmpMsg:action.msg
        }
    case 'GET_MANAGER_SUCCESS':
        return {
          ...state,
          managerList:action.response.data.data
        }
    case 'LEAVE_APPLY_REQUESTED':
        return {
          ...state,
          leaveApplyMessage: 'Wait...'
        }
    case 'LEAVE_APPLY_SUCCESS':
        return {
          ...state,
          leaveApplyMessage: action.response.data.msg
        }
    case 'LEAVE_ACTION_SUCCESS':
        return {
          ...state,
          leaveUpdateMsg:action.response.data.msg
        }
    case 'LEAVE_ACTION_FAILURE':
        return {
          ...state,
          leaveUpdateMsg:action.msg
        }
    case 'GET_HOLIDAYLIST_SUCCESS':
        return {
          ...state,
          holidayList: action.response.data.data
        }
    case 'GET_NEWS_SUCCESS':
        return {
          ...state,
          newsList: action.response.data.data
        }
    case 'GET_ALERTS_SUCCESS':
        return {
          ...state,
          alertsArr:action.response.data.data,
          unreadMsg:getUnreadMsg(action.response.data.data),
          notificationArr: state.onLoginShowNotify === false ? displayNotify(state.notificationArr, action.response.data.data) : showNotification(state.notificationArr, state.openNotification)
        }
    case 'UPDATE_PROFILE_REQUESTED':
        return {
          ...state,
          profileMessage:'Wait...',
          addEmpMsg:'Wait...'
        }
    case 'UPDATE_PROFILE_SUCCESS':
        return {
          ...state,
          addEmpMsg:action.response.data.msg,
          profileMessage: action.response.data.msg
        }
        case 'UPDATE_PROFILE_FAILURE':
            return {
              ...state,
              profileMessage: action.msg
            }
        case 'ADD_NEWSFEED_REQUESTED':
            return {
              ...state,
              addNewsMsg: 'Wait...'
            }
    case 'ADD_NEWSFEED_SUCCESS':
        return {
          ...state,
          addNewsMsg: action.response.data.msg
        }
    case 'ADD_HOLIDAY_REQUESTED':
        return {
          ...state,
          addHolidayMsg: 'Wait...'
        }
    case 'ADD_HOLIDAY_SUCCESS':
        return {
          ...state,
          addHolidayMsg: action.response.data.msg
        }
    case 'ADD_HOLIDAY_FAILURE':
        return {
          ...state,
          addHolidayMsg: action.msg
        }
    case 'READ_NOTIFY_SUCCESS':
        return {
          ...state
        }
    case 'GET_PUSH_NOTIFICATION_SUCCESS':
        return {
          ...state,
          notificationArr:action.response,
          openNotification: addData(action.response, state.openNotification),
          onLoginShowNotify:true,
          randomNo:Math.floor((Math.random() * 1000000) + 1)
        }
    case 'GET_OTP_SUCCESS':
        return {
          ...state,
          otpMsg:action.response.data.msg,
          otpFlag:true
        }
    case 'GET_OTP_FAILURE':
        return {
          ...state,
          otpMsg:action.msg,
          otpFlag:false
        }
    case 'RESET_PWD_SUCCESS':
        return {
          ...state,
          resetMsg:action.response.data.errorMsg,
          resetFlag:true
        }
    case 'RESET_PWD_FAILURE':
        return {
          ...state,
          resetMsg:action.msg,
          resetFlag:false
        }
    case 'SELECTED_APP':
    localStorage.setItem('selectedApp', action.value);
        return {
          ...state,
          selectedApp:action.value
        }
    case 'USER_TOUR_FLAG':
        return {
          ...state,
          tourFlag: action.value
        }
    case 'GET_EVENTS_REQUESTED':
        return {
          ...state
        }
    case 'GET_EVENTS_SUCCESS':
    let arr = [];
    if (Object.keys(action.response.data.data).length !== 0) {
      let eventArr = Object.assign({}, action.response.data.data);
      if (eventArr.Germany !== undefined) {
        eventArr.Germany.forEach((item, i) => {
          arr.push(item);
        });
      }
      if (eventArr.India !== undefined) {
        eventArr.India.forEach((item, i) => {
          arr.push(item);
        });
      }
    }

    // if (action.response.data.data.length !== 0 && action.response.data.data.length !== undefined) {
    //   arr = action.response.data.data
    // }
    //console.log("upcomingEvtArr: "+JSON.stringify(action.response.data));
        return {
          ...state,
          upcomingEvtArr:arr
        }
    case 'GET_EVENTS_FAILURE':
        return {
          ...state
        }
    case 'DELETE_EMPLOYEE_REQUESTED':
        return {
          ...state,
          addEmpMsg: 'Wait...'
        }
    case 'DELETE_EMPLOYEE_SUCCESS':
        return {
          ...state,
          addEmpMsg: action.response.data.msg
        }
    case 'DELETE_EMPLOYEE_FAILURE':
        return {
          ...state,
          addEmpMsg:action.msg
        }
    case 'VERIFY_TOKEN_REQUESTED':
        return {
          ...state,
          tokenMsg:''
        }
    case 'VERIFY_TOKEN_SUCCESS':
        return {
          ...state,
          tokenMsg: action.response.data.msg
        }
    case 'VERIFY_TOKEN_FAILURE':
        return {
          ...state,
          tokenMsg:''
        }
    default:
      return state;
  }
}
