import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {handleApiCall} from './../../../Redux/actions/index';
import {ADD_HOLIDAY_API, HOLIDAY_LIST_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';
import moment from 'moment';
import HolidayList from './holidayList';
import './holiday.css';

class HolidayAdd extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      startDate:'',
      country:'India',
      holidayName:'',
      addHolidayMsg:'',
      msgHide:true,
      holidayTypeSelected:'Add Holiday'
    }
  }
    componentDidMount(){
      let year = new Date().getFullYear();
      store.dispatch(handleApiCall(HOLIDAY_LIST_API, 'GET', 'GET_HOLIDAYLIST', {}, year,MOCK_FLAG));
      this.setState({
        startDate:'',
        country:'India',
        holidayTypeSelected:'Add Holiday'
      });
    }
    static getDerivedStateFromProps(props, state) {
        if (state.addHolidayMsg !== props.addHolidayMsg) {
          return {
            addHolidayMsg: props.addHolidayMsg
          }
        }
    return null;
    }
    countryChangeEvent = (value) =>{
      this.setState({country: value});
    }
    holidayTypeChangeEvent = (value) =>{
      if (value === 'Show Holiday') {
        this.resetData();
        let year = new Date().getFullYear();
        store.dispatch(handleApiCall(HOLIDAY_LIST_API, 'GET', 'GET_HOLIDAYLIST', {}, year,MOCK_FLAG));
      }
      this.setState({holidayTypeSelected: value});
    }
    handleStartDate = date => {
        this.setState({startDate: date});
    };
    holidayNameHandler(e){
      this.setState({holidayName: e.target.value})
    }
    addHoliday = (event) => {
      event.preventDefault();
      //var form = document.getElementById('holidayFormInfo');
      //if (form.checkValidity()) {
        this.setState({msgHide: false});
        //form.classList.remove('was-validated');
        let date = moment(this.state.startDate).format("DD MMM YYYY");
        let year = new Date(date).getFullYear();
        let obj = [{
          eventName: this.state.holidayName,
          country: this.state.country,
          eventDate: date,
          year: year
        }];
        store.dispatch(handleApiCall(ADD_HOLIDAY_API, 'POST', 'ADD_HOLIDAY', obj, '',MOCK_FLAG));
      //}
      // else {
      //   this.setState({news: ''});
      //   form.classList.add('was-validated');
      // }
    }
    resetData = () => {
      this.setState({
        startDate:'',
        country:'India',
        holidayName:'',
        msgHide: true,
        addHolidayMsg:''
      });
    }
    render() {
      return (
        <div>
          <div id="addHoliday">
            <h3>Add/view Holidays</h3>
            <hr />
            <div className="holidayForm">
            <div className="form-inline" id="selectHolidayType">
              <span>Select Option:</span>
              <DropdownButton className="country" title={this.state.holidayTypeSelected}>
              <Dropdown.Item onClick={() => this.holidayTypeChangeEvent('Add Holiday')} className="" id="Add">Add Holiday</Dropdown.Item>
              <Dropdown.Item onClick={() => this.holidayTypeChangeEvent('Show Holiday')} className="" id="Show">Show Holiday</Dropdown.Item>
            </DropdownButton>
            </div>
              {this.state.holidayTypeSelected === 'Add Holiday' ?
              <form id="holidayFormInfo" onSubmit={this.addHoliday}>
                  <div className="form-inline">
                    <span>Select Date:</span>
                    <DatePicker
                        required={true}
                        placeholderText="select date"
                        disabledKeyboardNavigation
                        dateFormat="dd MMM yyyy"
                        selected={this.state.startDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={this.handleStartDate}
                        className="form-control" />
                  </div>
                  <div className="form-inline">
                    <span>Select Country:</span>
                    <DropdownButton className="country" id="dropdown-basic-button" title={this.state.country}>
                    <Dropdown.Item onClick={() => this.countryChangeEvent('India')} className="" id="India">India</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.countryChangeEvent('Germany')} className="" id="Germany">Germany</Dropdown.Item>
                  </DropdownButton>
                  </div>
                  <div className="form-inline">
                    <span>Event Name:</span>
                    <input type="text"
                    className="form-control"
                    id="holidayName"
                    value={this.state.holidayName}
                    onChange={this.holidayNameHandler.bind(this)}
                    placeholder="Enter Event Name"
                    name="holidayName" required/>
                  </div>
                <div className="row button">
                  <div className="col-sm-2">
                      <Button className="btn btn-primary" type="submit">Add</Button>
                      <Button type="reset" variant="primary" id="resetBtn" onClick={this.resetData}>Reset</Button>
                  </div>
                </div>
                {!this.state.msgHide &&
                  <div className="row">
                    <div className="col-sm-4">
                      <p><b>{this.props.addHolidayMsg}</b></p>
                    </div>
                  </div>}
              </form>
            :
            <HolidayList
            holidayList={this.props.holidayList}
            />}
            </div>
          </div>
        </div>
      )
    }
  }

const mapStateToProps = (state) => {
    return {
      addHolidayMsg: state.Leave.addHolidayMsg,
      holidayList:state.Leave.holidayList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HolidayAdd);
