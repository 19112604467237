import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {handleApiCall} from './../../../Redux/actions/index';
import {MARK_ABSENT_API, MOCK_FLAG} from './../../../utils';
import "./markAbsent.css";
import store from './../../../Redux/store';

class MarkAbsent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      empAbsentKey:'',
      empAbsentName:'',
      fieldMsg:"",
      markAbsentMessage:'',
      msgHide:true
    }
  }
  componentDidMount(){
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedTab !== prevProps.selectedTab) {
      this.setState({
        empAbsentKey:'',
        empAbsentName:'',
        fieldMsg:"",
        markAbsentMessage:'',
        msgHide: true
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.markAbsentMessage !== props.markAbsentMessage) {
      return {
        markAbsentMessage: props.markAbsentMessage
      }
    }
    // Return null to indicate no change to state.
    return null;
  }
  empChangeEvent = (key, value) =>{
    this.setState({
      empAbsentKey: key,
      empAbsentName: value,
      fieldMsg:""
    });
  }

  absentReasonChange(e){
    this.setState({absentReason: e.target.value});
  }

  absentHandler = (event) =>{
    event.preventDefault();
    if (this.state.empAbsentKey !== "") {
      var from = moment(this.props.startDate, 'YYYY/MM/DD').format("DD-MM-YYYY");
      var to = moment(this.props.endDate, 'YYYY/MM/DD').format("DD-MM-YYYY");
      this.setState({fieldMsg:""});
      this.setState({msgHide: false});
      let obj = {
        fromDate:from,
        toDate: to,
        noOfDays: this.props.exactDiff,
        emp_Id: this.state.empAbsentKey,
        statusReason: this.state.absentReason
      }
      store.dispatch(handleApiCall(MARK_ABSENT_API, 'POST', 'MARK_ABSENT', obj, '',MOCK_FLAG));
    }
    else {
      this.setState({fieldMsg:"*Select employee."});
    }
  }

  render() {
    return (
      <form id="markAbsentForm" onSubmit={this.absentHandler}>
      <div className="row">
      <div className="col-sm-3">
      <div className="form-inline">
      <b>Employee Name:</b>
      <DropdownButton id="dropdown-basic-button" title={this.state.empAbsentName}>
        {this.props.allEmp.length > 0 && this.props.allEmp.map((item) => {
          if (item.emp_Id != localStorage.getItem('userID')) {
            return (
              <Dropdown.Item onClick={() => this.empChangeEvent(item.emp_Id, item.name)} className="" id="annualLeave">{item.name}</Dropdown.Item>
            );
          }
        })
        }
        </DropdownButton>
        <p style={{"margin":"auto", 'color':'#ce0808'}}>{this.state.fieldMsg}</p>
        </div>
      </div>
      </div>
      <div className="row">
      <div className="col-sm-2">
      <b>From Date:</b>
          <DatePicker
              required={true}
              excludeDates={this.props.disableHolidayArr}
              placeholderText="Select Date"
              disabledKeyboardNavigation
              dateFormat="dd MMM yyyy"
              // minDate={new Date(), subDays(new Date(), 14)}
              selected={this.props.startDate}
              onChange={this.props.handleStartDate}
              className="form-control" />
      </div>
      <div className="col-sm-2">
        <b>To Date:</b>
        <DatePicker
            required={true}
            excludeDates={this.props.disableHolidayArr}
            placeholderText="Select Date"
            disabledKeyboardNavigation
            minDate={this.props.startDate}
            dateFormat="dd MMM yyyy"
            selected={this.props.endDate}
            onChange={this.props.handleEndDate}
            className="form-control" />
      </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <b>Reason:</b>
          <input type="text"
          value={this.state.absentReason}
          className="form-control mb-2 mr-sm-2"
          id="days"
          placeholder="Enter Reason For Absent"
          onChange={this.absentReasonChange.bind(this)} required/>
        </div>
      </div>

      <div className="row">
      <div className="col-sm-2">
      <Button variant="success" type="submit" className="btn btn-primary">Save</Button>
      </div>
      </div>
      {!this.state.msgHide &&
      <div>
      <div className="col-sm-4">
          <p><b>{this.props.markAbsentMessage}</b></p>
      </div>
      </div>
          }
      </form>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      allEmp:state.Leave.allEmp,
      markAbsentMessage: state.Leave.markAbsentMessage
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarkAbsent);
