import React from 'react';
import './holiday.css';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';

const HolidayList = (props) => {
  return (
    <React.Fragment>
    <div className="col-12 mainDiv">
        <div className="col-12 col-sm-6 listDiv">
            <p className="countryName">India</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Event</th>
              </tr>
              </thead>
              <tbody>
              {
              props.holidayList.India &&
              props.holidayList.India.map((item) =>{
                return(
                  <tr>
                  <td>{item.eventDate}</td>
                  <td>{item.eventName}</td>
                  </tr>
              )
            })
          }
            </tbody>
            </Table>
        </div>
        <div className="col-12 col-sm-6 listDiv">
            <p className="countryName">Germany</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Event</th>
              </tr>
              </thead>
              <tbody>
              {
              props.holidayList.Germany &&
              props.holidayList.Germany.map((item) =>{
                return(
                  <tr>
                  <td>{item.eventDate}</td>
                  <td>{item.eventName}</td>
                  </tr>
              )
            })
          }
            </tbody>
            </Table>
        </div>
    </div>
    </React.Fragment>
  )
}

export default HolidayList;
