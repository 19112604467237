import React, { Component } from 'react';
import './Applications.css';
import './../../../css/dashboard.css';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connect} from'react-redux';
import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { bindActionCreators } from 'redux';
import {handleApiCall} from './../../../Redux/actions/index';
import {HR_REQUEST_LIST_API, HR_REQ_UPDATE_API, GET_NOTIFICATION_API, HR_SEND_QUERY_API, MOCK_FLAG} from './../../../utils';
import store from './../../../Redux/store';
import {Button, DropdownButton, Modal, Dropdown} from 'react-bootstrap';
import HistoryList from './historyTable';
import moment from 'moment';
import $ from 'jquery';

var myrowIndex = 0;
let reqSortFlag = true;
let respSortFlag = true;

const mapStateToProps = state =>{
  return{
    hrReqArr:state.Hr.hrReqArr,
    userInfo:state.Leave.userInfo,
    btnFlag:state.Hr.btnFlag,
    updateStatusMsg:state.Hr.updateStatusMsg,
    randomNo: state.Leave.randomNo
  }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    })
}


class Applications extends Component {
    constructor(props){
    super(props)
    this.state={
      modalFlag:false,
      btnFlag:false,
      hrReqArr:[],
      rejectReason:'',
      selectedRowId: 0,
      closeChatModal:false,
      raiseQueryMsg:'',
      selectedUser:'',
      randomNo:0
    }
  }

  componentDidMount(){
     let user = localStorage.getItem('userID');
     store.dispatch(handleApiCall(HR_REQUEST_LIST_API, 'GET', 'GET_USER_REQUEST', {}, user, MOCK_FLAG));
     store.dispatch(handleApiCall(GET_NOTIFICATION_API, 'GET', 'GET_ALERTS', {}, user,MOCK_FLAG));
     this.setState({btnFlag:true})
  }

  static getDerivedStateFromProps(props, state){
    if (props.randomNo !== state.randomNo) {
      return {
        randomNo: props.randomNo
      }
    }
    if (state.hrReqArr !== props.hrReqArr) {
      return {
        hrReqArr: props.hrReqArr
      }
    }
  	if (state.btnFlag !== props.btnFlag) {
      return {
        btnFlag: props.btnFlag
      }
    }
    // Return null to indicate no change to state.
    return null;
  }
componentDidUpdate(prevProps, prevState) {
  if (prevState.randomNo !== this.state.randomNo) {
    let user = localStorage.getItem('userID');
    store.dispatch(handleApiCall(HR_REQUEST_LIST_API, 'GET', 'GET_USER_REQUEST', {}, user,MOCK_FLAG));
  }
  //if (this.state.statusFlag) {
    if (prevProps.updateStatusMsg !== this.props.updateStatusMsg) {
      if (this.props.updateStatusMsg === 'Request Updated Successfully' || this.props.updateStatusMsg === 'Query Raised Successfully') {
        let user = localStorage.getItem('userID');
        store.dispatch(handleApiCall(HR_REQUEST_LIST_API, 'GET', 'GET_USER_REQUEST', {}, user, MOCK_FLAG));
        //this.setState({statusFlag: false});
      }
    }
  //}
}
  // statusChangeEvent(row, status){
  //
  // }
  handleReasonChange(e){
    this.setState({rejectReason: e.target.value});
  }

  handleQueryChange(e){
    this.setState({raiseQueryMsg: e.target.value});
  }

  updateStatus(row){
    let obj={
       "reqId": row.reqId,
       "reqStatus": row.reqStatus,
       "statusReason": row.statusReason
      }
    if (row.reqStatus === 'Rejected') {
      if (row.statusReason !== null && row.statusReason !== '') {
        //this.setState({statusFlag: true});
        store.dispatch(handleApiCall(HR_REQ_UPDATE_API, 'POST', 'REQUEST_UPDATE', obj, '',MOCK_FLAG));
        this.setState({btnFlag: true})
      }
      else {
        this.setState({modalFlag:true, selectedRowId: row.reqId, rejectReason: ''});
      }
    }
    else {
      //this.setState({statusFlag: true});
      store.dispatch(handleApiCall(HR_REQ_UPDATE_API, 'POST', 'REQUEST_UPDATE', obj, '',MOCK_FLAG));
    }
  }

  openChat = (row) =>{
    let user = row.employee.name;

    this.setState({closeChatModal: true, selectedRowId:row.reqId, selectedUser:user});
  }

  closeChatModal = (event) =>{
    event.preventDefault();
    this.setState({closeChatModal: false, raiseQueryMsg:'', selectedUser:''});
  }

  chatHandler = (event) =>{
    var form = document.getElementById('chatQuery');
    if (form.checkValidity() && (this.state.raiseQueryMsg.trim() !== '')) {
      form.classList.remove('was-validated');
      this.setState({closeChatModal: false, btnFlag: true});
      let obj = {
            reqId: this.state.selectedRowId,
            query: this.state.raiseQueryMsg
      }
      store.dispatch(handleApiCall(HR_SEND_QUERY_API, 'POST', 'RAISE_QUERY', obj, '',MOCK_FLAG));
    }
    else {
      form.classList.add('was-validated');
      this.setState({raiseQueryMsg: ''});
    }
  }

  formHandler = (evt) => {
    let obj={};
    var form = document.getElementById('rejectStatus');
    if (form.checkValidity() && (this.state.rejectReason.trim() !== '')) {
      form.classList.remove('was-validated');
      this.setState({modalFlag: false});
      let arr = [...this.state.hrReqArr];

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].reqId === this.state.selectedRowId) {
          arr[i].statusReason = this.state.rejectReason;
          obj = arr[i];
        }
      }
	  this.updateStatus(obj);
      this.setState({hrReqArr: arr});
    }
    else {
      form.classList.add('was-validated');
      this.setState({rejectReason: ''});
    }
  }

  closeModal = (event) => {
    event.preventDefault();
    this.setState({modalFlag: false, rejectReason: ''});
  }

formOptionChangeEvent = (value, row) => {
  let arr = [...this.state.hrReqArr];

  for (var i = 0; i < arr.length; i++) {
    if (arr[i].reqId === row.reqId) {
      if (arr[i].reqStatus !== value) {
        arr[i].enableBtn = false;
      }
      arr[i].reqStatus = value;
    }
  }
  this.setState({hrReqArr: arr});
  if (value === 'Rejected') {
    this.setState({modalFlag: true,selectedRowId: row.reqId})
  }
}

  reqDateSort = () =>{
    reqSortFlag = !reqSortFlag;
    let pointsToBeInserted = this.state.hrReqArr.sort((a,b) => {

        let x = a.createdOn, y = b.createdOn;
        if (x === y) {
          return 0;
        }
        else if (x === null) {
          return 1;
        }
        else if (y === null) {
         return -1;
       }
       else {
         if (reqSortFlag) {
           return x < y ? 1 : -1;
         }
         else {
           return x < y ? -1 : 1;
         }
       }

      });
    this.setState({hrReqArr: pointsToBeInserted});
  }

  respDateSort = () =>{
    respSortFlag = !respSortFlag;
    let pointsToBeInserted = this.state.hrReqArr.sort((a,b) => {

         let x = a.updatedOn, y = b.updatedOn;
         if (x === y) {
           return 0;
         }
         else if (x === null) {
           return 1;
         }
         else if (y === null) {
          return -1;
        }
        else {
          if (respSortFlag) {
            return x < y ? 1 : -1;
          }
          else {
            return x < y ? -1 : 1;
          }
        }


      });
    this.setState({hrReqArr: pointsToBeInserted});
  }
  render() {
    return (
    <div>
	{this.props.btnFlag &&
         <div className="row loaderclass loader">

           <div className="loader-outer">
             <div className="loader-inner">
         <Loader
           type="Oval"
           color="white"
           height={50}
           width={50}

           //4 secs

         /> </div></div> </div>}
        <Modal show={this.state.modalFlag} id="modalCss">
          <Modal.Header>
            <Modal.Title>Reason for rejecting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          <h6>Reason mandatory for rejecting.</h6>
          <form id="rejectStatus">
          <input type="text"
          maxlength="100"
          value={this.state.rejectReason}
          className="form-control mb-2 mr-sm-2"
          id="days"
          placeholder="Enter reason for rejection"
          onChange={this.handleReasonChange.bind(this)} required/>
          </form>
          </div></Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.formHandler}>Save</Button>
            <Button variant="danger" onClick={this.closeModal}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.closeChatModal} id="modalCss">
          <Modal.Body>
          <div>
          <h6><b>Enter query for {this.state.selectedUser}.</b></h6>
          <form id="chatQuery">
          <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Type here..."
                    value={this.state.raiseQueryMsg}
                    onChange={this.handleQueryChange.bind(this)} required/>
          </form>
          </div></Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.chatHandler}>Ok</Button>
            <Button variant="danger" onClick={this.closeChatModal}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        <h5>Pending Action</h5>
        <hr />
       <div className="chartCss">
       <div className={`row bottomRowCss ${this.props.userInfo.role}`}>
       <Table striped bordered id="applicationListTable">
       <thead>
       <tr>
       <th>Name</th>
       <th>Requirement</th>
       <th>Description</th>
       <th onClick={this.reqDateSort}>Request Date<i class="fa fa-fw fa-sort"></i></th>
       <th onClick={this.respDateSort}>Response Date<i class="fa fa-fw fa-sort"></i></th>
       <th>Status</th>
       <th>Reason for rejecting</th>
       <th>Action</th>
       </tr>
       </thead>
       <tbody>
        {
          this.state.hrReqArr.map((item) =>{
            if (item.disableFlag === true) {
              return (
                <tr>
                  <td>
                    <a data-tip data-for={item.employee.email}>{item.employee.name}</a>
                    <ReactTooltip id={item.employee.email} type='info'>
                      <span>{item.employee.email}</span>
                    </ReactTooltip>
                  </td>
                  <td>{item.requirement}</td>
                  <td>{item.description}</td>
                  <td>{item.createdOn !== null && moment.utc(item.createdOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</td>
                  <td>{item.updatedOn !== null && moment.utc(item.updatedOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</td>
                  <td><DropdownButton id="dropdown-basic-button" title={item.reqStatus}>
                    {(item.reqStatus !== 'In-Progress') &&
                    <Dropdown.Item
                      onClick={() => this.formOptionChangeEvent('In-Progress' , item)}
                      className=""
                      id="Add">In-Progress</Dropdown.Item>}
                      <Dropdown.Item
                        onClick={() => this.formOptionChangeEvent('Completed' , item)}
                        className=""
                        id="Add">Completed</Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => this.formOptionChangeEvent('Rejected' , item)}
                          className=""
                          id="Add">Rejected</Dropdown.Item>
                  </DropdownButton></td>
                  <td>
                    {item.statusReason}
                  </td>
                  <td>
                    <span className={`${item.enableBtn === true ? 'disableCss' : 'enableCss'}`}><i title="save row" className="fa fa-floppy-o" onClick={() => this.updateStatus(item)} aria-hidden="true"></i></span>
                    <span className="chatIcon"><i title="Raise query" onClick={() => this.openChat(item)} className="fa fa-comments" aria-hidden="true"></i></span>
                  </td>
                </tr>
              )
            }
          })
        }
       </tbody>
       </Table>
      </div>
      </div>

      <div>
        <h5>Application History</h5>
        <hr />
      </div>
          <div className="chartCss">
          <div className={`row bottomRowCss ${this.props.userInfo.role}`}>
          <HistoryList />
          </div>
          </div>
           </div>
    );
  }
}
export default
connect(
mapStateToProps,
mapDispatchToProps
)(Applications);
