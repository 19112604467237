import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonActions } from "./../../../Redux/actions/CommonActions";
import "bootstrap/dist/css/bootstrap.min.css";
import "./profile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Button } from "react-bootstrap";
import store from "./../../../Redux/store";
import { handleApiCall } from "./../../../Redux/actions/index";
import {
  EDIT_PROFILE_API,
  LOGGEDIN_EMP_API,
  MOCK_FLAG,
} from "./../../../utils";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      address: "",
      birthDate: "",
      userInfo: {
        email: "",
        first_name: "",
        last_name: "",
        address: "",
        dob: "",
      },
      msgHide: true,
      profileMessage: "",
    };
  }
  componentDidMount() {
    let user = localStorage.getItem("userID");
    store.dispatch(
      handleApiCall(LOGGEDIN_EMP_API, "GET", "GET_EMP", {}, user, MOCK_FLAG)
    );
    // let userData = {...this.props.userInfo};
    //
    // userData.dob = userData.dob == null ? "": moment(userData.dob, 'DD-MM-YYYY').toDate();
    // this.setState({userInfo: userData});
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.profileMessage !== this.state.profileMessage) {
      if (
        this.state.profileMessage === "Employee Details updated Successfully"
      ) {
        let user = localStorage.getItem("userID");
        store.dispatch(
          handleApiCall(LOGGEDIN_EMP_API, "GET", "GET_EMP", {}, user, MOCK_FLAG)
        );
      }
    }
    if (this.props.userInfo !== prevProps.userInfo) {
      let userData = { ...this.props.userInfo };

      userData.dob =
        userData.dob == null ? "" : moment(userData.dob, "DD-MM-YYYY").toDate();
      this.setState({ userInfo: userData });
    }
  }
  static getDerivedStateFromProps(props, state) {
    // if (state.userInfo !== props.userInfo) {
    //   let userData = {...props.userInfo};
    //   userData.dob = userData.dob == null ? "": moment(userData.dob, 'DD-MM-YYYY').toDate();
    //   return {
    //     userInfo: userData
    //   }
    // }
    if (state.profileMessage !== props.profileMessage) {
      return {
        profileMessage: props.profileMessage,
      };
    }

    return null;
  }
  inputChangeHandler(e) {
    let id = e.target.id;
    let userInfo = { ...this.state.userInfo };
    userInfo[id] = e.target.value;
    this.setState({ userInfo });
  }
  handleBirthDate = (date) => {
    let userInfo = { ...this.state.userInfo };
    userInfo["dob"] = date;
    this.setState({ userInfo });
  };
  saveProfile = (event) => {
    event.preventDefault();
    let birth =
      this.state.userInfo.dob !== ""
        ? moment(this.state.userInfo.dob).format("DD-MM-YYYY")
        : "";
    let obj = {
      first_name: this.state.userInfo.first_name,
      last_name: this.state.userInfo.last_name,
      address: this.state.userInfo.address,
      organisation: this.props.userInfo.organisation,
      dob: birth,
    };
    this.setState({ msgHide: false });
    let user = localStorage.getItem("userID");
    store.dispatch(
      handleApiCall(
        EDIT_PROFILE_API,
        "POST",
        "UPDATE_PROFILE",
        obj,
        user,
        MOCK_FLAG
      )
    );
  };
  render() {
    return (
      <div>
        <div id="profile">
          <h3>Personal Information</h3>
          <span className="glyphicon glyphicon-pencil"></span>
          <hr />
          <div className="profileForm">
            <form
              id="personalInfo"
              style={{ height: "100%" }}
              onSubmit={this.saveProfile}
            >
              <div className="row">
                <div className="col-sm-3 mb-3 mb-sm-0">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    value={this.state.userInfo.email}
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    disabled={true}
                  />
                </div>
                <div className="col-sm-3 mb-3 mb-sm-0">
                  <label htmlFor="first_name">First Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    value={this.state.userInfo.first_name}
                    onChange={this.inputChangeHandler.bind(this)}
                    placeholder="Enter First Name"
                    name="first_name"
                    required
                  />
                </div>
                <div className="col-sm-3">
                  <label htmlFor="last_name">Last Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    value={this.state.userInfo.last_name}
                    onChange={this.inputChangeHandler.bind(this)}
                    placeholder="Enter Last Name"
                    name="last_name"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-4 mb-sm-0">
                  <label htmlFor="address">Address:</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    id="address"
                    value={this.state.userInfo.address}
                    onChange={this.inputChangeHandler.bind(this)}
                    name="address"
                    required
                  ></textarea>
                </div>
                <div className="col-sm-3">
                  <b className="mr-2 mr-sm-0">
                    <span>Select DOB:</span>
                  </b>
                  <DatePicker
                    maxDate={new Date()}
                    placeholderText="select date"
                    dateFormat="dd MMM yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={this.state.userInfo.dob}
                    onChange={this.handleBirthDate}
                    className="form-control"
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">
                  <Button type="submit" className="btn btn-primary">
                    Save
                  </Button>
                </div>
              </div>

              {!this.state.msgHide && (
                <div>
                  <div className="col-sm-4">
                    <p>
                      <b>{this.props.profileMessage}</b>
                    </p>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Leave.userInfo,
    profileMessage: state.Leave.profileMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      leaveHandler: CommonActions.leaveHandler,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
