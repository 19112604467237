import { combineReducers } from 'redux';
import LeaveReducer from "./LeaveReducer";
import HrReducer from "./HrReducer";
const appReducer = combineReducers({
  Leave: LeaveReducer,
  Hr: HrReducer
});
const rootReducer = (state, action) => {
  return appReducer(state, action)
}
export default rootReducer;
