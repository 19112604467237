import axios from 'axios';
import { API_URL, REQUESTED, SUCCESS, FAILURE, PUSH_NOTIFICATION_API, MOCK_URL} from './../../utils';

let source = null;

export const handleApiCall = (api, method, type, params, appendInUrl, mockFlag) => {
    return (dispatch) => {
      let url = (mockFlag === false ? API_URL : MOCK_URL) + api;
      url += appendInUrl !== '' ? '/'+appendInUrl : '';
      let apiCall = '';
      let header = {
        Authorization: api === '/login' || api === '/forgot_password/generate_otp' || api === '/forgot_password/reset_password' ? ''
         : `Bearer ${localStorage.getItem('token')}`
      };
      if (method === 'POST') {
        apiCall =  axios.post(url, params, {headers: header})
      }
      else {
        apiCall = axios.get(url,{params: params, headers: header})
      }
      dispatch({type: type + REQUESTED})
      return apiCall
      .then(response => {
          dispatch({type: type + SUCCESS, response})
      })
      .catch(error => {
        let msg = "";
        if (error.response !== undefined) {
          msg = error.response.data.errorMsg;
        }
        if (error.response !== undefined && (msg === 'Invalid token')) {
          dispatch({ type: 'LOGOUT_SUCCESS'})
        }
        else {
          dispatch({type: type + '_FAILURE', msg})
        }
      })
    }
}
