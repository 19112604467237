import { API_URL, REQUESTED, SUCCESS, FAILURE} from './../../utils';
import axios from 'axios';

export class CommonActions{
   static activePage(value) {
     return {
         type: "ACTIVE_PAGE",
         value
       };
   }

   static setApp(value){
     return {
         type: "SELECTED_APP",
         value
       };
   }

   static setTourFlag(value){
     return {
       type: "USER_TOUR_FLAG",
       value
     }
   }


 }
